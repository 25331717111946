let $currentCapability = null
let $classCapabilityRelation = null
class  ResourceManagement {
  constructor(){
  }

  bindEvents() {
    this.handleOpenForm()
    this.handleClickBulkUpdate()
    this.handleBulkUpdateSubmit()
    this.handleMonthPicker()
    this.handleCalculateTotalCap()
  }

  handleMonthPicker() {
    $('.month-picker').datepicker({
      format: "mm-yyyy",
      startView: "months",
      minViewMode: "months"
    });
  }
  handleOpenForm() {
    $(".set-capability").each((index, element) => {
      $(element).popover({
        placement: 'bottom',
        title : '<span>Set capacity</span>'+
                '<button type="button" id="close" class="close" onclick="$(&quot;.set-capability&quot;).popover(&quot;hide&quot;);">&times;</button>',
        html: true,
        content:  $(element).find("form"),
        sanitize: false,
      })
    })

    $('.set-capability').on('click', (e) => {
      $currentCapability = $(e.currentTarget)
      $classCapabilityRelation = $(e.currentTarget).data("classCapabilityRelation")
      $('.set-capability').popover('hide')
      $('.message-error-capability').html('')
      $(e.currentTarget).popover('show')
    });

    $(document).on('click', '.btn-save-capability', (e) => {
      const inputData = $(e.target).closest('form').serialize()
      const capabilityId = $(e.target).closest('form').data('capabilityId')
      let httpMethod = 'post'
      let urlCapability = Routes.capabilities_path()

      if (!!capabilityId) {
        httpMethod = 'patch'
        urlCapability = Routes.capability_path(capabilityId)
      }

      $.ajax({
        url: urlCapability,
        type: httpMethod,
        dataType: 'json',
        data: inputData,
        success: (data) => {
          if (data.errors) {
            $('.message-error-capability').html(data.errors)
          } else {
            $currentCapability.find('.capacity_value').html(data[$currentCapability.data('capacityWeek')])
            $(e.target).closest('form').data('capabilityId', data.id)
            $('.set-capability').popover('hide')
            $($classCapabilityRelation).each((index, element) => {
              $(element).find('form').data('capabilityId', data.id)
            })
            this.handleCalculateTotalCap();
          }
        },
        error: (err) => {
          $('.message-error-capability').html('Some thing went wrong !')
          error(err)
        }
      })
    })
  }

  handleClickBulkUpdate() {
    $(document).on('click', '.btn-capacity-bulk-update', (e) => {
      $('.message-error-submit-bulk-update').addClass('d-none')
      const projectStaffId = $(e.target).data('projectStaffId')
      $("#capacityBulkUpdate #projects_staff_id").val(projectStaffId)
    })
  }

  handleBulkUpdateSubmit() {
    $(document).on('click', '.btn-submit-bulk-update', (e) => {
      const inputData = $(e.target).closest('form').serialize()
      $.ajax({
        url: Routes.bulk_update_capabilities_path(),
        type: 'post',
        dataType: 'json',
        data: inputData,
        success: (data) => {
          if (data.errors) {
            $('.message-error-submit-bulk-update').removeClass('d-none')
            $('.message-error-submit-bulk-update > span').html(data.errors)
          } else {
            location.reload();
          }
        },
        error: (err) => {
          $('.message-error-submit-bulk-update').html('Some thing went wrong !')
          error(err)
        }
      })
    })
  }

  handleCalculateTotalCap() {
    for (let month = 0; month <= 2; month++) {
      for (let week= 1; week <= 4; week++) {
        let cap = 0
        $('.table-resource-managements').find('.month_'+month+'_week_'+week).each((index, element) => {
          cap = parseFloat((cap + (isNaN(parseFloat($(element).find('.capacity_value').html())) ? 0 : parseFloat($(element).find('.capacity_value').html()))).toFixed(10))
          $('.line_total_month_'+ month +'_week_' + week).html(cap)
        })
      }
    }
  }
}

export default ResourceManagement
