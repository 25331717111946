class EstimateToolsProjectBase {
  constructor(){
  }

  bindEvents() {
    this.handleEstimateToolPreviewForm()
    this.handleOnchangeAssign()
    this.handleOnchangePercent()
    this.handleOnchangeMissionType()
    this.handleCalculatorTotal()
    this.handleRemoveRaw()
    this.handleOnchangeRiskRatio()
    this.handleOpenForm()
    this.handleOnchangeDiscount()
    this.handleCalculatorOperationTime()
    this.handleRemoveTask()
    this.handlechangeTimeOfTask()
    this.handlechangeFieldOfEstimate()
  }

  handleOnchangeDiscount() {
    $(document).on('change', ".discount-jp, .discount-au", (e) => {
      this.handleCalculatorTotal()
    })
  }

  handleOpenForm() {
    $('.percent-form').hide()
    $('.edit-percent').on('click', (e) => {
      $('.percent-form').hide()
      $('.estimate-title').show()
      $(e.target).closest('thead').find(".percent-form").show()
      $(e.target).closest('thead').find(".estimate-title").hide()
      $(e.target).closest('td').find(".percent-form").show()
      $(e.target).closest('td').find(".estimate-title").hide()
    });
    $('.btn-cancel-set-percent').on('click', (e) => {
      $(e.target).closest('thead').find(".percent-form").hide()
      $(e.target).closest('thead').find(".estimate-title").show()
      $(e.target).closest('td').find(".percent-form").hide()
      $(e.target).closest('td').find(".estimate-title").show()
    });
  }

  handleEstimateToolPreviewForm() {
    $('.btn-preview').on('click', (e) => {
      const actionURL = $('.estimate-tool-form').attr('action')
      let method = $('.estimate-tool-form').attr('method')
      // set method and target when click
      $('.estimate-tool-form').attr('target', '_blank')
      $('.estimate-tool-form').attr('action', Routes.preview_estimate_tools_path())
      $('.estimate-tool-form').attr('method', 'post')

      if($('.estimate-tool-form input:first').attr('value') == 'patch') {
        $('.estimate-tool-form input:first').attr('value', 'post')
      }

      $('.estimate-tool-form').submit();

      // update method and target back
      if($('.estimate-tool-form input:first').attr('value') == 'post') {
        $('.estimate-tool-form input:first').attr('value', 'patch')
      }
      $('.estimate-tool-form').attr('action', actionURL)
      $('.estimate-tool-form').attr('method', method)
    })
  }

  handleOnchangeAssign() {
    $(document).on('change', ".department-budget", (e) => {
      this.handleCalculatorTotal()
    })
  }

  handleOnchangeMissionType() {
    $(document).on('change', '.mission-type', (e) => {
      this.handleCalculatorTotal()
    })
  }

  handleOnchangePercent() {
    $(document).on('change', '.percent-budget', (e) => {
      this.handleCalculatorTotal()
    })
  }

  handleOnchangeRiskRatio() {
    $(document).on('change', ".risk-ratio", (e) => {
      this.handleCalculatorTotal()
    })
  }

  calculatorTotalCostWithRisk(totalCost, percent) {
    const percentToFloat = parseFloat(percent || 0)
    return (totalCost * (1 + percentToFloat/100))
  }

  handleRemoveRaw() {
    $('.estimate-tool-budget').on('cocoon:after-remove', (e, insertedItem, originalEvent) => {
      this.handleCalculatorTotal()
    })
  }

  handleCalculatorTotal(){
    const budgetSymbol = $('.estimation-tool').data('projectCurrencySymbol')
    const currencyNameConvert = $('.estimation-tool').data('currencyNameConvert')
    const currencyExchangeRateJpy = $('.estimation-tool').data('currencyExchangeRateJpy')
    const currencyExchangeRateAud = $('.estimation-tool').data('currencyExchangeRateAud')

    let total = 0
    let totalWithRisk = 0
    let totalWithRiskAndOP = 0
    let operationBudget = 0
    let consultationBudget = 0
    let designBudget = 0
    let requirementBudget = 0
    let developBudget = 0
    let unitTestBudget = 0
    let systemTestBudget = 0
    let deploymentBudget = 0
    let projectManagementBudget = 0
    let otherBudget = 0

    let operationPercent = 0
    let consultationPercent = 0
    let designPercent = 0
    let requirementPercent = 0
    let developPercent = 0
    let unitTestPercent = 0
    let systemTestPercent = 0
    let deploymentPercent = 0
    let projectManagementPercent = 0
    let otherPercent = 0
    let sumAuMarket = 0
    let sumJapanMarket = 0

    let operationSaleAU = 0
    let consultationSaleAU = 0
    let designSaleAU = 0
    let requirementSaleAU = 0
    let developSaleAU = 0
    let unitTestSaleAU = 0
    let systemTestSaleAU = 0
    let deploymentSaleAU = 0
    let projectManagementSaleAU = 0
    let otherSaleAU = 0

    let operationSaleJP = 0
    let consultationSaleJP = 0
    let designSaleJP = 0
    let requirementSaleJP = 0
    let developSaleJP = 0
    let unitTestSaleJP = 0
    let systemTestSaleJP = 0
    let deploymentSaleJP = 0
    let projectManagementSaleJP = 0
    let otherSaleJP = 0

    $('.au-market').html(0)
    $('.au-market').data('marketAuRate', 0)
    $('.jp-market').html(0)
    $('.jp-market').data('marketJpRate', 0)
    $('.estimate-tool-budget').find('.nested-fields:not([style*="display: none"])').each((index, element) => {
      const type = $(element).find(".mission-type").find('option:selected').data('type')
      const salaryRatePerHour = $(element).find(".department-budget").find('option:selected').data('rate')
      const percent = $(element).find(".percent-budget").val()
      const missionType = $(element).find(".mission-type").find('option:selected').data('hour')
      const monthRateAu =  $(element).closest('.row').find(".department-budget").find('option:selected').data('marketRateAu')
      const monthRateJapan=  $(element).closest('.row').find(".department-budget").find('option:selected').data('marketRateJapan')
      let costAUMarket = (monthRateAu/22/8 * missionType) || 0
      let costJapanMarket = (monthRateJapan/22/8 * missionType) || 0
      $('.au-market-'+type).html(this.addDotForNumber((costAUMarket * percent / 100).toFixed(0)))
      $('.au-market-'+type).data('marketAuRate', (costAUMarket * percent / 100))
      $('.jp-market-'+type).html(this.addDotForNumber((costJapanMarket * percent / 100).toFixed(0)))
      $('.jp-market-'+type).data('marketJpRate', (costJapanMarket * percent / 100))

      switch(type) {
        case "design":
          designBudget += parseFloat((salaryRatePerHour * missionType * percent / 100) || 0)
          designPercent += parseFloat(percent || 0)
          designSaleJP += costJapanMarket * percent / 100
          designSaleAU += costAUMarket * percent / 100
          $('.au-market-'+type).html(this.addDotForNumber(designSaleAU.toFixed(0)))
          $('.au-market-'+type).data('marketAuRate', designSaleAU)
          $('.jp-market-'+type).html(this.addDotForNumber(designSaleJP.toFixed(0)))
          $('.jp-market-'+type).data('marketJpRate', designSaleJP)
          break;
        case "operation":
          operationBudget += parseFloat((salaryRatePerHour * missionType * percent / 100) || 0)
          operationPercent += parseFloat(percent || 0)
          operationSaleJP += costJapanMarket * percent / 100
          operationSaleAU += costAUMarket * percent / 100
          $('.au-market-'+type).html(this.addDotForNumber(operationSaleAU.toFixed(0)))
          $('.au-market-'+type).data('marketAuRate', operationSaleAU)
          $('.jp-market-'+type).html(this.addDotForNumber(operationSaleJP.toFixed(0)))
          $('.jp-market-'+type).data('marketJpRate', operationSaleJP)
          break;
        case "consultation":
          consultationBudget += parseFloat((salaryRatePerHour * missionType * percent / 100) || 0)
          consultationPercent += parseFloat(percent || 0)
          consultationSaleJP += costJapanMarket * percent / 100
          consultationSaleAU += costAUMarket * percent / 100
          $('.au-market-'+type).html(this.addDotForNumber(consultationSaleAU.toFixed(0)))
          $('.au-market-'+type).data('marketAuRate', consultationSaleAU)
          $('.jp-market-'+type).html(this.addDotForNumber(consultationSaleJP.toFixed(0)))
          $('.jp-market-'+type).data('marketJpRate', consultationSaleJP)
          break;
        case "requirement":
          requirementBudget += parseFloat((salaryRatePerHour * missionType * percent / 100) || 0)
          requirementPercent += parseFloat(percent || 0)
          requirementSaleJP += costJapanMarket * percent / 100
          requirementSaleAU += costAUMarket * percent / 100
          $('.au-market-'+type).html(this.addDotForNumber(requirementSaleAU.toFixed(0)))
          $('.au-market-'+type).data('marketAuRate', requirementSaleAU)
          $('.jp-market-'+type).html(this.addDotForNumber(requirementSaleJP.toFixed(0)))
          $('.jp-market-'+type).data('marketJpRate', requirementSaleJP)
          break;
        case "develop":
          developBudget += parseFloat((salaryRatePerHour * missionType * percent / 100) || 0)
          developPercent += parseFloat(percent || 0)
          developSaleJP += costJapanMarket * percent / 100
          developSaleAU += costAUMarket * percent / 100
          $('.au-market-'+type).html(this.addDotForNumber(developSaleAU.toFixed(0)))
          $('.au-market-'+type).data('marketAuRate', developSaleAU)
          $('.jp-market-'+type).html(this.addDotForNumber(developSaleJP.toFixed(0)))
          $('.jp-market-'+type).data('marketJpRate', developSaleJP)
          break;
        case "unit_test":
          unitTestBudget += parseFloat((salaryRatePerHour * missionType * percent / 100) || 0)
          unitTestPercent += parseFloat(percent || 0)
          unitTestSaleJP += costJapanMarket * percent / 100
          unitTestSaleAU += costAUMarket * percent / 100
          $('.au-market-'+type).html(this.addDotForNumber(unitTestSaleAU.toFixed(0)))
          $('.au-market-'+type).data('marketAuRate', unitTestSaleAU)
          $('.jp-market-'+type).html(this.addDotForNumber(unitTestSaleJP.toFixed(0)))
          $('.jp-market-'+type).data('marketJpRate', unitTestSaleJP)
          break;
        case "system_test":
          systemTestBudget += parseFloat((salaryRatePerHour * missionType * percent / 100) || 0)
          systemTestPercent += parseFloat(percent || 0)
          systemTestSaleJP += costJapanMarket * percent / 100
          systemTestSaleAU += costAUMarket * percent / 100
          $('.au-market-'+type).html(this.addDotForNumber(systemTestSaleAU.toFixed(0)))
          $('.au-market-'+type).data('marketAuRate', systemTestSaleAU)
          $('.jp-market-'+type).html(this.addDotForNumber(systemTestSaleJP.toFixed(0)))
          $('.jp-market-'+type).data('marketJpRate', systemTestSaleJP)
          break;
        case "deployment":
          deploymentBudget += parseFloat((salaryRatePerHour * missionType * percent / 100) || 0)
          deploymentPercent += parseFloat(percent || 0)
          deploymentSaleJP += costJapanMarket * percent / 100
          deploymentSaleAU += costAUMarket * percent / 100
          $('.au-market-'+type).html(this.addDotForNumber(deploymentSaleAU.toFixed(0)))
          $('.au-market-'+type).data('marketAuRate', deploymentSaleAU)
          $('.jp-market-'+type).html(this.addDotForNumber(deploymentSaleJP.toFixed(0)))
          $('.jp-market-'+type).data('marketJpRate', deploymentSaleJP)
          break;
        case "project_management":
          projectManagementBudget += parseFloat((salaryRatePerHour * missionType * percent / 100) || 0)
          projectManagementPercent += parseFloat(percent || 0)
          projectManagementSaleJP += costJapanMarket * percent / 100
          projectManagementSaleAU += costAUMarket * percent / 100
          $('.au-market-'+type).html(this.addDotForNumber(projectManagementSaleAU.toFixed(0)))
          $('.au-market-'+type).data('marketAuRate', projectManagementSaleAU)
          $('.jp-market-'+type).html(this.addDotForNumber(projectManagementSaleJP.toFixed(0)))
          $('.jp-market-'+type).data('marketJpRate', projectManagementSaleJP)
          break;
        case "other":
          otherBudget += parseFloat((salaryRatePerHour * missionType * percent / 100) || 0)
          otherPercent += parseFloat(percent || 0)
          otherSaleJP += costJapanMarket * percent / 100
          otherSaleAU += costAUMarket * percent / 100
          $('.au-market-'+type).html(this.addDotForNumber(otherSaleAU.toFixed(0)))
          $('.au-market-'+type).data('marketAuRate', otherSaleAU)
          $('.jp-market-'+type).html(this.addDotForNumber(otherSaleJP.toFixed(0)))
          $('.jp-market-'+type).data('marketJpRate', otherSaleJP)
          break;
        default:
          // code block
      }


    })

    $('.total-cost-operation').html(budgetSymbol + this.convertCurrency(operationBudget))
    $('.total-cost-consultation').html(budgetSymbol + this.convertCurrency(consultationBudget))
    $('.total-cost-requirement').html(budgetSymbol + this.convertCurrency(requirementBudget))
    $('.total-cost-develop').html(budgetSymbol + this.convertCurrency(developBudget))
    $('.total-cost-unit_test').html(budgetSymbol + this.convertCurrency(unitTestBudget))
    $('.total-cost-system_test').html(budgetSymbol + this.convertCurrency(systemTestBudget))
    $('.total-cost-deployment').html(budgetSymbol + this.convertCurrency(deploymentBudget))
    $('.total-cost-project_management').html(budgetSymbol + this.convertCurrency(projectManagementBudget))
    $('.total-cost-other').html(budgetSymbol + this.convertCurrency(otherBudget))
    $('.total-cost-design').html(budgetSymbol + this.convertCurrency(designBudget))

    $('.total-percent-operation').html(this.addDotForNumber(operationPercent.toFixed(0)))
    $('.total-percent-consultation').html(this.addDotForNumber(consultationPercent.toFixed(0)))
    $('.total-percent-requirement').html(this.addDotForNumber(requirementPercent.toFixed(0)))
    $('.total-percent-develop').html(this.addDotForNumber(developPercent.toFixed(0)))
    $('.total-percent-unit_test').html(this.addDotForNumber(unitTestPercent.toFixed(0)))
    $('.total-percent-system_test').html(this.addDotForNumber(systemTestPercent.toFixed(0)))
    $('.total-percent-deployment').html(this.addDotForNumber(deploymentPercent.toFixed(0)))
    $('.total-percent-project_management').html(this.addDotForNumber(projectManagementPercent.toFixed(0)))
    $('.total-percent-other').html(this.addDotForNumber(otherPercent.toFixed(0)))
    $('.total-percent-design').html(this.addDotForNumber(designPercent.toFixed(0)))

    const requirementBudgetWithRiskRatio = this.calculatorTotalCostWithRisk(requirementBudget, $('.requirement-risk-ratio').val())
    const developBudgetWithRiskRatio = this.calculatorTotalCostWithRisk(developBudget, $('.develop-risk-ratio').val())
    const unitTestBudgetWithRiskRatio = this.calculatorTotalCostWithRisk(unitTestBudget, $('.unit-test-risk-ratio').val())
    const systemTestBudgetWithRiskRatio = this.calculatorTotalCostWithRisk(systemTestBudget, $('.system-test-risk-ratio').val())
    const deploymentBudgetWithRiskRatio = this.calculatorTotalCostWithRisk(deploymentBudget, $('.deployment-risk-ratio').val())
    const projectManagementBudgetWithRiskRatio = this.calculatorTotalCostWithRisk(projectManagementBudget, $('.project-management-risk-ratio').val())
    const otherBudgetWithRiskRatio = this.calculatorTotalCostWithRisk(otherBudget, $('.other-risk-ratio').val())
    const designBudgetWithRiskRatio = this.calculatorTotalCostWithRisk(designBudget, $('.design-risk-ratio').val())
    const operationBudgetWithRiskRatio = this.calculatorTotalCostWithRisk(operationBudget, $('.operation-risk-ratio').val())
    const consultationBudgetWithRiskRatio = this.calculatorTotalCostWithRisk(consultationBudget, $('.consultation-risk-ratio').val())

    $('.total-cost-with-risk-ratio-requirement').html(budgetSymbol + this.convertCurrency(requirementBudgetWithRiskRatio))
    $('.total-cost-with-risk-ratio-develop').html(budgetSymbol + this.convertCurrency(developBudgetWithRiskRatio))
    $('.total-cost-with-risk-ratio-unit_test').html(budgetSymbol + this.convertCurrency(unitTestBudgetWithRiskRatio))
    $('.total-cost-with-risk-ratio-system_test').html(budgetSymbol + this.convertCurrency(systemTestBudgetWithRiskRatio))
    $('.total-cost-with-risk-ratio-deployment').html(budgetSymbol + this.convertCurrency(deploymentBudgetWithRiskRatio))
    $('.total-cost-with-risk-ratio-project_management').html(budgetSymbol + this.convertCurrency(projectManagementBudgetWithRiskRatio))
    $('.total-cost-with-risk-ratio-other').html(budgetSymbol + this.convertCurrency(otherBudgetWithRiskRatio))
    $('.total-cost-with-risk-ratio-design').html(budgetSymbol + this.convertCurrency(designBudgetWithRiskRatio))
    $('.total-cost-with-risk-ratio-operation').html(budgetSymbol + this.convertCurrency(operationBudgetWithRiskRatio))
    $('.jp-market-operation').data('marketJpRate', operationBudgetWithRiskRatio*currencyExchangeRateJpy/currencyExchangeRateAud)
    $('.au-market-operation').data('marketAuRate', operationBudgetWithRiskRatio)
    if (currencyNameConvert == "AUD") {
      $('.jp-market-operation').html(this.convertCurrency(operationBudgetWithRiskRatio))
      $('.au-market-operation').html(this.convertCurrency(operationBudgetWithRiskRatio/currencyExchangeRateJpy*currencyExchangeRateAud))

    } else {
      $('.jp-market-operation').html(this.convertCurrency(operationBudgetWithRiskRatio*currencyExchangeRateJpy/currencyExchangeRateAud))
      $('.au-market-operation').html(this.convertCurrency(operationBudgetWithRiskRatio))
    }
    $('.total-cost-with-risk-ratio-consultation').html(budgetSymbol + this.convertCurrency(consultationBudgetWithRiskRatio))

    total = requirementBudget +
            developBudget +
            unitTestBudget +
            systemTestBudget +
            deploymentBudget +
            projectManagementBudget +
            otherBudget +
            designBudget +
            consultationBudget

    totalWithRisk = requirementBudgetWithRiskRatio +
                    developBudgetWithRiskRatio +
                    unitTestBudgetWithRiskRatio +
                    systemTestBudgetWithRiskRatio +
                    deploymentBudgetWithRiskRatio +
                    projectManagementBudgetWithRiskRatio +
                    otherBudgetWithRiskRatio +
                    designBudgetWithRiskRatio +
                    consultationBudgetWithRiskRatio
    totalWithRiskAndOP = totalWithRisk + operationBudgetWithRiskRatio
    $('.estimate-tool-total-cost').html(budgetSymbol+ this.convertCurrency(total))
    $('.estimate-tool-total-cost-including-op-cost').html(budgetSymbol+ this.convertCurrency(totalWithRiskAndOP))
    const totalWithRiskTransferToJPY = totalWithRisk*currencyExchangeRateJpy/currencyExchangeRateAud
    const totalWithRiskTransferToAud = totalWithRisk
    $('.cost-within-risk-ratio').find('.jp-market').each((index, element) => {
      if(!element.classList.contains('jp-market-operation')) {
        sumJapanMarket += $(element).data('marketJpRate')
      }
    })
    $('.cost-within-risk-ratio').find('.au-market').each((index, element) => {
      if(!element.classList.contains('au-market-operation')) {
        sumAuMarket += $(element).data('marketAuRate')
      }
    })
    $('.sum-jp-market').html(this.addDotForNumber(sumJapanMarket.toFixed(0)))
    $('.sum-au-market').html(this.addDotForNumber(sumAuMarket.toFixed(0)))
    let discountJP = $('.discount-jp').val()
    let discountAU = $('.discount-au').val()
    let totalAmountJP = (sumJapanMarket+ operationBudgetWithRiskRatio*currencyExchangeRateJpy/currencyExchangeRateAud).toFixed(0)
    let totalAmountAU = (sumAuMarket + operationBudgetWithRiskRatio).toFixed(0)

    $('.estimate-tool-total-cost-with-risk-aud').html(this.addDotForNumber(totalWithRiskTransferToAud.toFixed(0)))
    $('.estimate-tool-total-cost-with-risk-jpy').html(this.addDotForNumber((isFinite(totalWithRiskTransferToJPY) ? totalWithRiskTransferToJPY : 0).toFixed(0)))
    $('.profit-jp').html(this.addDotForNumber(totalAmountJP))
    $('.profit-au').html(this.addDotForNumber(totalAmountAU))
    $('.gross-exclude-op-profit-au').html(this.addDotForNumber((totalAmountAU-totalWithRiskTransferToAud).toFixed(0)))
    $('.gross-exclude-op-profit-jp').html(this.addDotForNumber((totalAmountJP-totalWithRiskTransferToJPY).toFixed(0)))

    $('.gross-profit-margin-jp').html((((totalAmountJP-totalWithRiskTransferToJPY)*100/totalAmountJP) || 0).toFixed(1)+"%")
    $('.gross-profit-margin-au').html((((totalAmountAU-totalWithRiskTransferToAud)*100/totalAmountAU) || 0).toFixed(1)+"%")
    $('.net-profit-include-back-office-jp').html(this.addDotForNumber((sumJapanMarket-totalWithRiskTransferToJPY).toFixed(0)))
    $('.net-profit-include-back-office-au').html(this.addDotForNumber((sumAuMarket-totalWithRiskTransferToAud).toFixed(0)))
    $('.net-profit-margin-jp').html((((sumJapanMarket-totalWithRiskTransferToJPY)*100/totalAmountJP) || 0).toFixed(1)+"%")
    $('.net-profit-margin-au').html((((sumAuMarket-totalWithRiskTransferToAud)*100/totalAmountAU) || 0).toFixed(1)+"%")
    $('.total-jp').html(this.addDotForNumber((totalAmountJP - discountJP).toFixed(0)))
    $('.total-au').html(this.addDotForNumber((totalAmountAU - discountAU).toFixed(0)))
    $('.net-profit-after-discount-jp').html(this.addDotForNumber((totalAmountJP - discountJP - totalWithRiskAndOP*currencyExchangeRateJpy/currencyExchangeRateAud).toFixed(0)))
    $('.net-profit-after-discount-au').html(this.addDotForNumber((totalAmountAU - discountAU - totalWithRiskAndOP).toFixed(0)))
    $('.net-profit-margin-after-discount-jp').html((((totalAmountJP - discountJP - totalWithRiskAndOP*currencyExchangeRateJpy/currencyExchangeRateAud)*100/(totalAmountJP - discountJP)) || 0).toFixed(1)+"%")
    $('.net-profit-margin-after-discount-au').html((((totalAmountAU - discountAU - totalWithRiskAndOP)*100/(totalAmountAU - discountAU)) || 0).toFixed(1)+"%")
  }

  handleRemoveTask() {
    $('.estimate-tool-monthly-budget-sales').on('cocoon:after-remove', (e, insertedItem, originalEvent) => {
      this.handleCalculatorOperationTime()
    })
  }

  handlechangeTimeOfTask() {
    $(document).on('change', ".estimate-task-time", (e) => {
      this.handleCalculatorOperationTime()
    })
  }

  handlechangeFieldOfEstimate() {
    $(document).on('change', ".estimate-field", (e) => {
      this.handleCalculatorOperationTime()
    })
  }

  handleCalculatorOperationTime() {
    let totalTimeOfTasks = 0
    $('#estimate_task').find('.nested-fields:not([style*="display: none"])').each((index, element) => {
      const timeOfTask = parseInt($(element).find(".estimate-task-time").val()) || 0
      totalTimeOfTasks += timeOfTask
    })
    const timeRequirement = totalTimeOfTasks * parseInt($('.estimate-requirement-percent').val()) / 100
    const timeDesign = totalTimeOfTasks * parseInt($('.estimate-design-percent').val()) / 100
    const timeUnitTest = totalTimeOfTasks * parseInt($('.estimate-unit-test-percent').val()) / 100
    const timeSystemTest = totalTimeOfTasks * parseInt($('.estimate-system-test-percent').val()) / 100
    const timeDeployment = totalTimeOfTasks * parseInt($('.estimate-deployment-percent').val()) / 100
    const timeProjectManagement = totalTimeOfTasks * parseInt($('.estimate-project-management-percent').val()) / 100
    const timeOther = totalTimeOfTasks * parseInt($('.estimate-other-percent').val()) / 100
    const timeConsultation = parseInt($('.estimate-consultation-percent').val())
    const timeDevelop = totalTimeOfTasks
    const timeOfProject = timeRequirement +
                          timeDesign +
                          timeUnitTest +
                          timeSystemTest +
                          timeDeployment +
                          timeProjectManagement +
                          timeOther +
                          timeConsultation +
                          timeDevelop
    $('.operation-time').val(timeOfProject*25/100)
  }

  addDotForNumber(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  convertCurrency(amount) {
    const currencyExchangeRateAud = $('.estimation-tool').data('currencyExchangeRateAud')
    const projectExchangeRate = $('.estimation-tool').data('projectExchangeRate')

    if ( currencyExchangeRateAud == 0 || projectExchangeRate == 0 ) {
      return 0
    } else {
      return this.addDotForNumber((amount*projectExchangeRate/currencyExchangeRateAud).toFixed(0))
    }
  }
}

export default EstimateToolsProjectBase
