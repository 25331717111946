let $currentTaxRates = null
class MonthlyOffsetReports {
  constructor(){
  }

  bindEvents() {
    this.handleDateOfInput()
    this.handlePopoverTaxRatesUpdateForm()
  }

  handleDateOfInput() {
    $('.month-freee-report').datepicker({
      format: "mm-yyyy",
      startView: "months",
      minViewMode: "months"
    });
  }

  handlePopoverTaxRatesUpdateForm() {
    $(".set-tax-rates").each((index, element) => {
      $(element).popover({
        placement: 'bottom',
        title : '<span>Tax Rates Form</span>'+
                '<button type="button" id="close" class="close" onclick="$(&quot;.set-tax-rates&quot;).popover(&quot;hide&quot;);">&times;</button>',
        html: true,
        content: $(element).find('form'),
        sanitize: false,
      })
    })

    $('.set-tax-rates').on('click', (e) => {
      $currentTaxRates = $(e.currentTarget)
      $('.set-tax-rates').popover('hide')
      $('.message-error-tax-rates').html('')
      $(e.currentTarget).popover('show')
    });

    $(document).on('click', '.btn-save-tax-rates', (e) => {
      const inputData = $(e.target).closest('form').serialize()
      const urlTaxRatesUpdate = $(e.target).closest('form').attr('action')
      $.ajax({
        url: urlTaxRatesUpdate,
        type: 'patch',
        dataType: 'json',
        data: inputData,
        success: (data) => {
          if (data.errors) {
            $('.message-error-tax-rates').html(data.errors)
          } else {
            $currentTaxRates.html(data.tax_rates)
            $('.set-tax-rates').popover('hide')
          }
        },
        error: (err) => {
          $('.message-error-tax-rates').html('Some thing went wrong !')
          error(err)
        }
      })
    })
  }
}

export default MonthlyOffsetReports
