class Capacity {
  constructor(){
  }

  bindEvents() {
    this.handleDateOfInput()
    this.handleEventsOfStaffCapacityStaffIdInput()
    this.handleStaffCapacityStaffIdInputOption()
    this.handleMonthOfInput()
  }

  handleMonthOfInput() {
    $('.month-of-input').datepicker({
      format: 'mm/yyyy',
      viewMode: 'months',
      minViewMode: 'months'
    });
  }

  handleDateOfInput() {
    $('.week-of-input').datepicker({
      format: 'dd/mm/yyyy',
      todayHighlight: true,
      weekStart: 1
    });

    $('.week-of-input').datepicker()
      .on('changeDate', (e) => {
        const currentValue = $(e.currentTarget).val()
        const nextValue = moment(currentValue, 'DD/MM/YYYY').startOf('isoWeek').format('DD/MM/YYYY')

        $(e.currentTarget).datepicker('update', nextValue)
      });
  }

  handleEventsOfStaffCapacityStaffIdInput() {
    $('#staff-capacities')
      .on('cocoon:after-insert', () => {
        this.handleStaffCapacityStaffIdInputOption()
      })
      .on('cocoon:after-remove', () => {
        this.handleStaffCapacityStaffIdInputOption()
      });

    $(document).on('change', '#staff-capacities .staff-capacity-staff-id-input', () => {
      this.handleStaffCapacityStaffIdInputOption()
    })
  }

  handleStaffCapacityStaffIdInputOption() {
    $('#staff-capacities .staff-capacity-staff-id-input option').removeAttr('disabled');

    $('#staff-capacities .nested-fields:visible .staff-capacity-staff-id-input').each((index, e) => {
      const currentValue = $(e).val()
      const $selectedOptions = $('#staff-capacities .nested-fields:visible select option:selected')

      $selectedOptions.each((index, option) => {
        const selectedValue = option.value;

        if (!!selectedValue){
          let querySelector = `option[value=${selectedValue}]`

          if (!!currentValue) {
            querySelector += `[value!=${currentValue}]`
          }
          const $options =  $(e).find(querySelector)

          $options.attr('disabled', true)
        }
      })
    })
  }
}

export default Capacity
