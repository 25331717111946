let $currentStaffHoliday = null

class StaffHoliday {
  constructor(){
  }

  bindEvents() {
    this.handlePopoverScheduleLeaveForm()
    this.customDatePicker()
  }

  customDatePicker() {
    $('.datepicker-leave-schedule').datepicker({
      format: 'mm/yyyy',
      viewMode: 'months',
      minViewMode: 'months'
    })
  }

  handlePopoverScheduleLeaveForm() {
    $(".set-staff-holiday").each((index, element) => {
      $(element).popover({
        placement: 'bottom',
        title : '<span>Schedule Leave Form</span>'+
                '<button type="button" id="close" class="close" onclick="$(&quot;.set-staff-holiday&quot;).popover(&quot;hide&quot;);">&times;</button>',
        html: true,
        content: $(element).find('form'),
        sanitize: false,
      })
    })

    $('.set-staff-holiday').on('click', (e) => {
      $currentStaffHoliday = $(e.currentTarget)
      $('.set-staff-holiday').popover('hide')
      $('.message-error-staff-holiday').html('')
      $(e.currentTarget).popover('show')
    });

    $(document).on('click', '.btn-cancel-staff-holiday', (e) => {
      const staffHolidayId = $(e.target).closest('form').data('staffHolidayId')
      const teamType = $(e.target).closest('form').data('teamType')

      if (!!staffHolidayId && confirm('Are you sure you want to delete this?')) {
        const urlDeleteStaffHoliday = Routes.staff_holiday_path(teamType, staffHolidayId)

        $.ajax({
          url: urlDeleteStaffHoliday,
          type: 'delete',
          dataType: 'json',
          success: (data) => {
            if (data.errors) {
              $('.message-error-staff-holiday').html(data.errors)
            } else {
              $currentStaffHoliday.html('')
              $currentStaffHoliday.css("background", '#fff')
              $(e.target).closest('form').data('staffHolidayId', '')
              $(e.target).closest('form').find('.absence-type-input').val('')
              $('.set-staff-holiday').popover('hide')
            }
          },
          error: (err) => {
            $('.message-error-staff-holiday').html('Some thing went wrong !')
            error(err)
          }
        })
      } else {
        $('.set-staff-holiday').popover('hide')
      }
    })

    $(document).on('click', '.btn-save-staff-holiday', (e) => {
      const inputData = $(e.target).closest('form').serialize()
      const staffHolidayId = $(e.target).closest('form').data('staffHolidayId')
      const teamType = $(e.target).closest('form').data('teamType')

      let httpMethod = 'post'
      let urlStaffHoliday = Routes.staff_holidays_path(teamType)

      if (!!staffHolidayId) {
        httpMethod = 'patch'
        urlStaffHoliday = Routes.staff_holiday_path(teamType, staffHolidayId)
      }

      $.ajax({
        url: urlStaffHoliday,
        type: httpMethod,
        dataType: 'json',
        data: inputData,
        success: (data) => {
          if (data.errors) {
            $('.message-error-staff-holiday').html(data.errors)
          } else {
            $currentStaffHoliday.html(data.absence.code)
            $currentStaffHoliday.css("background", data.absence.color)
            $(e.target).closest('form').data('staffHolidayId', data.id)
            $('.set-staff-holiday').popover('hide')
          }
        },
        error: (err) => {
          $('.message-error-staff-holiday').html('Some thing went wrong !')
          error(err)
        }
      })
    })

  }
}

export default StaffHoliday
