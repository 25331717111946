class project {
  constructor(){
  }

  bindEvents() {
    this.handleChangeProjectType()
    // this.handleHorizontalScrollbar()
    this.handleCustomerChange()
    this.handleSelectedDepartment()
    this.handleMonthOfInput()
  }

  handleMonthOfInput() {
    $('.month-of-input').datepicker({
      format: 'mm/yyyy',
      viewMode: 'months',
      minViewMode: 'months'
    });
  }

  handleSelectedDepartment() {
    $('.department-select').on('change', (e) => {
      if ($('.department-select').data('is-edit-project-and-refresh-page')) {
        $('.department-select').data('is-edit-project-and-refresh-page', false)
        $('.projects-wrapper .staff-position').each((index, element) => {
          $(element).select2("enable", false)
        })
      } else {
        $('.projects-wrapper .staff-position').empty().select2();
        let pleaseSelect = [{id: "", text: "Please select .."}]
        if ($('.department-select').find('option:selected').data('staff-collection')) {
          $('.staff-position').select2({
            data: [...pleaseSelect, ...$('.department-select').find('option:selected').data('staff-collection')]
          });
        }
      }
    }).trigger('change')

    $('body').on('cocoon:after-insert', (e, insertedItem, originalEvent) => {
      $(insertedItem).find('select.staff-position').empty().select2();
      let pleaseSelect = [{id: "", text: "Please select .."}]

      $('.staff-position').select2({
        data: [...pleaseSelect, ...$('.department-select').find('option:selected').data('staff-collection')]
      });
    })
  }

  handleChangeProjectType() {
    $('.customer-project-type').on('change', (e) => {
      if($('.customer-project-type').val()=='project_base') {
        $('.project_budget').show()
        $('.project_monthly').hide()
      } else {
        $('.project_budget').hide()
        $('.project_monthly').show()
      }
    }).trigger('change')
  }

  // handleHorizontalScrollbar() {
  //   $('.wrapper1').on('scroll', function (e) {
  //     $('.wrapper2').scrollLeft($('.wrapper1').scrollLeft());
  //   });
  //   $('.wrapper2').on('scroll', function (e) {
  //       $('.wrapper1').scrollLeft($('.wrapper2').scrollLeft());
  //   });
  //   $(window).on('load', function (e) {
  //     $('.content1').width($('table').width());
  //     $('.content2').width($('table').width());
  //   });
  // }

  handleCustomerChange() {
    $(".customer-agent").append('<option value="n/a">N/A</option>');
    $('.select-customer').on('change', (e) => {
      const agentId = $(e.target).find('option:selected').data('agent-id')

      if (agentId) {
        $('.customer-agent').val($(e.target).find('option:selected').data('agent-id')).select2()
      } else {
        $('.customer-agent').val("n/a").select2()
      }
    }).trigger('change')
  }
}

export default project
