let $currentFormURL = null
class StaffForm {
  constructor(){
  }

  bindEvents() {
    this.handleCreateUserForm()
    this.handleEditFormLink()
    this.handleOnBoardingTaskClick()
    this.handleTaskClick()
  }

  handleOnBoardingTaskClick() {
    $('.onboarding-task').on('click', (e) => {
      const isOnboardingTaskChecked = e.target.checked

      $(e.target).closest('.card').find('.onboarding-sub-task').each((index, element) => {
        if (isOnboardingTaskChecked) {
          $(element).prop("checked", true);
        } else {
          $(element).prop("checked", false);
        }
      })
    })
  }

  handleTaskClick() {
    $('.onboarding-sub-task').on('click', (e) => {
      if ($(e.target).closest('.card-body').find('input.onboarding-sub-task:not(:checked)').length > 0) {
        $(e.target).closest('.card').find('.onboarding-task').prop("checked", false);
      } else {
        $(e.target).closest('.card').find('.onboarding-task').prop("checked", true);
      }
    })
  }

  handleCreateUserForm() {
    $('.is-check-create-user').on('change', (e) => {
      $(".user-form").hide()
      if ($(e.target).is(':checked')) {
        $(".user-form").show()
        $(".user-email").val($(".sazae-email").val())
        $(".user-name").val($(".sazae-email").val())
      }
    }).trigger('change')
  }

  handleEditFormLink() {
    $(".edit-form-link").each((index, element) => {
      $(element).popover({
        placement: 'bottom',
        title : '<span>Form</span>'+
                '<button type="button" id="close" class="close" onclick="$(&quot;.edit-form-link&quot;).popover(&quot;hide&quot;);">&times;</button>',
        html: true,
        content: $(element).find('.content-popover-form-url'),
        sanitize: false,
      })
    })

    $('.edit-form-link').on('click', (element) => {
      $currentFormURL = $(element.currentTarget)
      $('.edit-form-link').popover('hide')
      $(element.currentTarget).popover('show')
    })

    $(document).on('click', '.btn-save-form-link', (e) => {
      const onboardingTaskDetailsOnboardingId = $(e.target).closest('.content-popover-form-url').data('onboarding-task-details-onboarding-id')
      const inputData = $(e.target).closest('.content-popover-form-url').find('.form-input').val()
      const urlUpdateOnboarding = Routes.update_link_staffs_path()
      const currentLink = $currentFormURL.closest('.content-form-url').find('.form-url')


      const a = $(e.target).closest('.content-popover-form-url').find('.form-input')

      if (!onboardingTaskDetailsOnboardingId) {
        $.ajax({
          url: urlUpdateOnboarding,
          type: 'patch',
          dataType: 'json',
          data: {
            form: inputData,
            onboarding: a.data('onboarding'),
            onboarding_task_details_onboarding: a.data('onboarding-task-details-onboarding')
          },
          success: (data) => {
            console.log(data['onboarding'])
            console.log(a.attr('data-onboarding', data['onboarding']))
            currentLink.attr("href", inputData)
            $('.edit-form-link').popover('hide')

            if (!inputData) {
              currentLink.addClass('d-none')
            } else {
              currentLink.removeClass('d-none')
            }
          }
        })
      } else {
        $.ajax({
          url: urlUpdateOnboarding,
          type: 'patch',
          dataType: 'json',
          data: {
            onboarding_task_details_onboarding_id: onboardingTaskDetailsOnboardingId,
            form: inputData
          },
          success: (data) => {
            currentLink.attr("href", inputData)
            $('.edit-form-link').popover('hide')

            if (!inputData) {
              currentLink.addClass('d-none')
            } else {
              currentLink.removeClass('d-none')
            }
          }
        })
      }
    })
  }
}

export default StaffForm
