class customer {
  constructor(){
  }

  bindEvents() {
    this.handleChangeClientType()
    this.handleHorizontalScrollbar()
  }

  handleChangeClientType() {
    $('.customer-client-type').on('change', (e) => {
      if($('.customer-client-type').val()=='agent') {
        $('.customer-agency').hide()
      } else {
        $('.customer-agency').show()
      }
    }).trigger('change')
  }

  handleHorizontalScrollbar() {
    $('.wrapper1').on('scroll', function (e) {
      $('.wrapper2').scrollLeft($('.wrapper1').scrollLeft());
    });
    $('.wrapper2').on('scroll', function (e) {
        $('.wrapper1').scrollLeft($('.wrapper2').scrollLeft());
    });
    $(window).on('load', function (e) {
      $('.content1').width($('table').width());
      $('.content2').width($('table').width());
    });
  }
}

export default customer
