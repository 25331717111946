class ProjectTimeLog {
  constructor(){
  }

  bindEvents() {
    this.handleChangeDurationTime()
    this.handleSelectedProject()
    this.handleProjectChange()
  }

  handleSelectedProject() {
    $('.project-time-log').on('change', (e) => {
      $('.project-time-logs-wrapper .staff-position').empty().select2();
      let pleaseSelect = [{id: "", text: "Please select .."}]
      if ($('.project-time-log').find('option:selected').data('staff-collection')) {
        $('.project-time-logs-wrapper .staff-position').select2({
          data: [...pleaseSelect, ...$('.project-time-log').find('option:selected').data('staff-collection')]
        });

        $('.staff-position').val($('.project-time-log').data('staff-id')).select2()
      }
    }).trigger('change')
  }

  handleChangeDurationTime() {
    $('.duration').on('blur', (e) => {
      var duration = $(e.target).val().toString();
      if (duration.split('.').length > 1) {
        $(e.target).val(duration.split('.')[0] + ":" + Math.round(parseFloat("0." + duration.split('.')[1])*60));
        return
      }
      if($(e.target).val().length != 0){
        if(duration.length == 1){
          $(e.target).val(String("0" + duration));
        }
        if(duration.indexOf(':') == -1){
          $(e.target).val(duration.toString() + ':00');
        }
        if(duration.indexOf(':') == 1){
          if(duration.length <= 3){
            $(e.target).val(this.addZero(duration));
          }

          if(duration.length > 3){
            $(e.target).val(this.addZeroBeforeNumberSplit(parseFloat(duration.split(':')[0]) + Math.floor(duration.split(':')[1] / 60) + ':' + ( Math.floor(duration.split(':')[1]) - Math.floor(duration.split(':')[1] / 60) *60)));
          }
        }
        if(duration.indexOf(':') >= 2){
          if(duration.split(':')[1].length < 2){
            $(e.target).val(this.addZero(duration));
          }
          if(duration.split(':')[1].length >= 2){
            if(parseFloat(duration.split(':')[1]) > 60) {
              $(e.target).val(this.addZeroBeforeNumberSplit(parseFloat(duration.split(':')[0]) + Math.floor(duration.split(':')[1] / 60) + ':' + ( Math.floor(duration.split(':')[1]) - Math.floor(duration.split(':')[1] / 60) *60)));
            }
          }
        }
      }
    })
  }

  handleProjectChange() {
    $(".customer").append('<option value="n/a">N/A</option>');
    $('.project-time-log').on('change', (e) => {
      const customerId = $(e.target).find('option:selected').data('customer-id')
      if (customerId) {
        $('.customer').val($(e.target).find('option:selected').data('customer-id')).select2()
      } else {
        $('.customer').val("n/a").select2()
      }
    }).trigger('change')
  }

  addZero(str) {
    let str_convert = str
    if (str_convert.split(':')[1].length == 2) {
      return str_convert
    } else {
      str_convert = str_convert.toString() + '0'
      str_convert = this.addZero(str_convert)
    }
    return str_convert
  }

  addZeroBeforeNumberSplit(str) {
    let str_convert = str
    if (str_convert.split(':')[1].length == 1) {
      str_convert = str_convert.split(':')[0] + ":" + "0" + str_convert.split(':')[1]
      return str_convert
    }
    return str_convert
  }
}

export default ProjectTimeLog
