class EstimateTools {
  constructor(){
  }

  bindEvents() {
    this.handleChangeProject()
  }

  handleChangeProject() {
    $('.project-base-block').hide()

    $('.estimate-tool-project').on('change', (e) => {
      const projectType = $(e.target).find('option:selected').data('projectType')
      if(projectType == 'project_base') {
        $('.project-base-block').show()
        $('.monthly-base-block').hide()
      } else {
        $('.project-base-block').hide()
        $('.monthly-base-block').show()
      }
    }).trigger('change')
  }
}

export default EstimateTools
