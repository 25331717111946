let $sidebarNicescroll = null

class General {
  constructor(){
  }

  bindEvents() {
    this.handleSelect2()
    this.handleStaffSelect2()
    this.handleDatePicker()
    this.handleAppMessages()
    this.handleLeftSidebar()
    this.handleInputWithTypeNumber()
    this.validateAttachmentMaxFileSize()
    this.handleButtonApproveOrReject()
    this.addScrollBarOnTopTable()
    this.handleMonthOfInput()
  }

  handleAppMessages() {
    const hideTime = $("#app-messages").data('hide-time')

    if (!!hideTime) {
      if (isNaN(hideTime)) {
        console.warn(`hide_time must be a number`)
      }

      setTimeout(
        () => {
          $("#app-messages").fadeOut(3000)
        },
        hideTime
      );
    }
  }

  handleStaffSelect2() {
    $(".staff-search-select2").each((index, element) => {
      $(element).select2({
        theme: "bootstrap",
        ajax: {
          url: Routes.search_staffs_path(),
          type: 'GET',
          dataType: 'json',
          data: (params) => {
            return {
              key_word: params.term,
            };
          },
          processResults: (data) => {
            return {
              results: data.results
            };
          }
        }
      });
    })
  }

  handleDatePicker(){
    $('.datepicker').datepicker({
      format: 'dd/mm/yyyy',
      todayHighlight: true,
      weekStart: 1
    });

    $(document).ready(function(){
      $('.datepicker').datepicker();
      $('#sazae-internal-system-app').on('cocoon:after-insert', (e, insertedItem) => {
        $('.datepicker').datepicker({
          format: 'dd/mm/yyyy',
          todayHighlight: true,
          weekStart: 1
        });
      });
    });
  }

  handleMonthOfInput() {
    $('.month-of-input').datepicker({
      format: 'mm/yyyy',
      viewMode: 'months',
      minViewMode: 'months'
    });

    $(document).ready(function(){
      $('.month-of-input').datepicker();
      $('#sazae-internal-system-app').on('cocoon:after-insert', (e, insertedItem) => {
        $('.month-of-input').datepicker({
          format: 'mm/yyyy',
          viewMode: 'months',
          minViewMode: 'months'
        });
      });
    });
  }

  handleSelect2() {
    $('.select2').select2({theme: 'bootstrap'})
    $('body').on('cocoon:after-insert', (e, insertedItem, originalEvent) => {
      $('.select2').select2({theme: 'bootstrap'})
    })
  }

  handleLeftSidebar() {
    if($('.main-sidebar').length) {
      $sidebarNicescroll = $('.main-sidebar').getNiceScroll();

      $('#sidebar-wrapper').on('click', '.nav-link-dropdown', (e) => {
        const $firstLiParent = $(e.currentTarget).closest('li.nav-item')
        const $firstChildUl = $firstLiParent.find('ul.dropdown-menu').first()

        if ($firstChildUl.length) {
          if ($(e.currentTarget).hasClass('slide-down')) {
            $(e.currentTarget).removeClass('slide-down')

            $firstChildUl.slideUp(500, () => {
              this.updateSidebarNicescroll()
            })
          } else {
            $(e.currentTarget).addClass('slide-down')

            $firstChildUl.slideDown(500, () => {
              this.updateSidebarNicescroll()
            })
          }
        }
      })

      $('#sidebar-wrapper .sidebar-menu li.active').parents('li.nav-item').addClass('active')

      $('#sidebar-wrapper .sidebar-menu li.active').parents('li.nav-item').children('.nav-link-dropdown').trigger('click')
    }
  }

  updateSidebarNicescroll() {
    let interval = setInterval(() => {
      if($sidebarNicescroll != null) {
        $sidebarNicescroll.resize();
      }
    }, 10);

    setTimeout(() => {
      clearInterval(interval);
    }, 600);
  }

  handleInputWithTypeNumber() {
    $(document).on('input', 'input[type="number"]', (e) => {
      const currentVal = Number($(e.target).val())
      const minVal = Number($(e.target).attr('min'))
      const maxVal = Number($(e.target).attr('max'))

      if (minVal == 0 && !$(e.target).val()) {
        $(e.target).val('')
      }

      if (currentVal < minVal) {
        $(e.target).val(minVal)
      }

      if (currentVal > maxVal) {
        $(e.target).val(maxVal)
      }
    })
  }

  validateAttachmentMaxFileSize() {
    $('.attachment-input').on('change', (e) => {
      const currentFileSize = $(e.target)[0].files[0].size
      const maxFileSize = Number($(e.target).attr('max_file_size'))
      if (currentFileSize > maxFileSize) {
        alert($(e.target).data('message'))
        $('.form-actions .btn-primary').prop('disabled', true)
        $('.form-actions .btn-primary').addClass('disabled')

      } else {
        $('.form-actions .btn-primary').prop('disabled', false)
        $('.form-actions .btn-primary').removeClass('disabled')
      }
    });
  }

  handleButtonApproveOrReject() {
    $('.btn-approval').on('click', (e) => {
      setTimeout(this.disableFunction, 1);
    })
  }

  disableFunction() {
    $('.btn-approval').prop('disabled', true)
  }

  addScrollBarOnTopTable() {
    $('.table-responsive').each((index, element) => {
      const scrollHelper = `scroll-wrapper-${index}`
      const scrollableClass = `scroll-${index}`
      const scrollableEl = $(`<div class="scroll-helper ${scrollHelper}"><div class="scrollable ${scrollableClass}"></div></div>`)

      scrollableEl.insertBefore($(element))

      $(document).ready(function(){
        $(`.${scrollableClass}`).css('width', $(element).find('table').width())
      });

      $(`.${scrollHelper}`).scroll(function() {
        $(element).scrollLeft($(this).scrollLeft());
      });
      $(element).scroll(function() {
        $(`.${scrollHelper}`).scrollLeft($(this).scrollLeft());
      });
    });

    $(window).resize(function () {
      $('.table-responsive').each((index, element) => {
        const scrollableClass = `scroll-${index}`

        $(`.${scrollableClass}`).css('width', $(element).find('table').width())
      });
    });
  }
}

export default General
