class TravelAllowanceClaim {
  constructor(){
  }

  bindEvents() {
    this.handleChangeAmount()
    this.handleRemoveRaw()
  }

  handleChangeAmount() {
    $(document).on('change', ".travel-allowance-amount", (e) => {
      this.calculatorSubTotal()
    })
  }

  handleRemoveRaw() {
    $('.travel-allowance-claim-details').on('cocoon:after-remove', (e, insertedItem, originalEvent) => {
      this.calculatorSubTotal()
    });
  }

  calculatorSubTotal() {
    let sub_total = 0
    $('.travel-allowance-claim-details').find('.nested-fields:not([style*="display: none"])').each((index, element) => {
      const amount = $(element).find(".travel-allowance-amount").val()
      sub_total+=parseFloat(amount || 0)
    })
    $('.travel-allowance-sub-total').html(sub_total)
  }
}

export default TravelAllowanceClaim
