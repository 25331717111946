class Lead {
  constructor(){
  }

  bindEvents() {
    this.handleDateOfInput()
    this.handleChangeProjectType()
    this.handleMonthOfInput()
    this.handleChangeTitleOfLeadTerm()
    this.handleMonthlyFeeChange()
    this.handleDurationTimeChange()
  }

  handleChangeProjectType() {
    $('.lead-project-type').on('change', (e) => {
      if($('.lead-project-type').val()=='total_budget') {
        $('.lead_budget').show()
        $('.lead_term').show()
        $('.lead_monthly_fee').hide()
        $('.monthly-fee-amount-total-budget').hide()

      } else {
        $('.lead_budget').hide()
        $('.lead_term').hide()
        $('.lead_monthly_fee').show()
        $('.monthly-fee-amount-total-budget').show()
        this.handleCalculateMonthlyFeeTotalBudget()
      }
    }).trigger('change')
  }

  handleMonthOfInput() {
    $('.month-of-input').datepicker({
      format: 'mm/yyyy',
      viewMode: 'months',
      minViewMode: 'months'
    });

    $(document).ready(function(){
      $('.month-of-input').datepicker();
      $('#sazae-internal-system-app').on('cocoon:after-insert', (e, insertedItem) => {
        $('.month-of-input').datepicker({
          format: 'mm/yyyy',
          viewMode: 'months',
          minViewMode: 'months'
        });
      });
    });
  }

  handleDateOfInput() {
    $('.datepicker-custom').datepicker({
      format: 'yyyy-mm-dd',
      todayHighlight: true,
      orientation: 'bottom'
    });
  }

  handleChangeTitleOfLeadTerm() {
    $('form').on('cocoon:after-insert', function(e, inserted_item) {
      inserted_item.find('.title-term').val("Term " +  $('.simple_form').find('.nested-fields:not([style*="display: none"])').length)
    });
  }

  handleMonthlyFeeChange() {
    $('.monthly-fee-amount').on('change', (e) => {
      this.handleCalculateMonthlyFeeTotalBudget()
    })
  }

  handleDurationTimeChange() {
    $('.lead_duration_start_month_input, .lead_duration_end_month_input').on('change', (e) => {
      this.handleCalculateMonthlyFeeTotalBudget()
    })
  }

  handleCalculateMonthlyFeeTotalBudget() {
    const totalBudget = $(".monthly-fee-amount").val()
    const leadDurationStartMonth = $('.lead_duration_start_month_input').val()
    const leadDurationEndMonth = $('.lead_duration_end_month_input').val()
    let one = moment(leadDurationStartMonth, 'MM-YYYY')
    let two = moment(leadDurationEndMonth, 'MM-YYYY')
    let monthBetweenTwoDate = Math.round(moment.duration(two.diff(one)).asMonths())
    if (monthBetweenTwoDate > 0) {
      $('.monthly-total-budget').html(this.addDotForNumber(monthBetweenTwoDate*totalBudget))
    } else {
      $('.monthly-total-budget').html(0)
    }
  }

  addDotForNumber(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}
export default Lead
