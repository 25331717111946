class EstimateToolsMonthlyBase {
  constructor(){
  }

  bindEvents() {
    this.handleCurrencyMonthlyBudget()
    this.handleCalculatorSaleTotal()
    this.handleChangePositionSale()
    this.handleChangeAmountSale()
    this.handleChangeCapSale()
    this.handleCalculatorCostTotal()
    this.handleChangePositionCost()
    this.handleChangeAmountCost()
    this.handleChangeCapCost()
    this.handleRemoveRawSale()
    this.handleRemoveRawCost()
    this.handleAddRawSale()
    this.handleAddRawCost()
    this.handleChangeOperationPercent()
    this.handleChangeOperationAmount()
  }

  handleChangeOperationPercent() {
    $(document).on('change', ".operation-percent", (e) => {
      this.handleChangeOperationCost()
      this.handleCalculatorProfit()
    })
  }

  handleChangeOperationCost() {
    const operationPercent = $('.operation-percent').val()
    const currencySymbolConvert = $('.estimation-tool').data('currencySymbolConvert')
    const currencyNameConvert = $('.estimation-tool').data('currencyNameConvert')
    $('.estimate-tool-monthly-operation-cost .operation-cost').each((index, element) => {
      let budgetCost = $(element).data("budgetCostOfMonth")
      $(element).val((budgetCost*parseInt(operationPercent)/100).toFixed(0))
      $(element).closest('td').find('small.operation-cost-text').html("To " + currencyNameConvert + ": " + currencySymbolConvert + this.addDotForNumber(this.convert_currency(budgetCost*operationPercent/100).toFixed(0)))
    })
  }

  handleChangeOperationAmount() {
    $(document).on('change', ".operation-cost", (e) => {
      const amount = $(e.target).val()
      const currencySymbolConvert = $('.estimation-tool').data('currencySymbolConvert')
      const currencyNameConvert = $('.estimation-tool').data('currencyNameConvert')
      $(e.target).closest('td').find('small.operation-cost-text').html("To " + currencyNameConvert + ": " + currencySymbolConvert + this.addDotForNumber(this.convert_currency(amount).toFixed(0)))
      this.handleCalculatorProfit()
    })
  }

  handleRemoveRawSale() {
    $('.estimate-tool-monthly-budget-sales').on('cocoon:after-remove', (e, insertedItem, originalEvent) => {
      this.handleCalculatorSaleTotal()
    })
  }

  handleRemoveRawCost() {
    $('.estimate-tool-monthly-budget-cost').on('cocoon:after-remove', (e, insertedItem, originalEvent) => {
      this.handleCalculatorCostTotal()
      this.handleChangeOperationCost()
    })
  }

  handleAddRawSale() {
    $('body').on('cocoon:after-insert', (e, insertedItem, originalEvent) => {
      this.handleCalculatorSaleTotal()
    })
  }

  handleAddRawCost() {
    $('.estimate-tool-monthly-budget-cost').on('cocoon:after-insert', (e, insertedItem, originalEvent) => {
      this.handleCalculatorCostTotal()
    })
  }

  handleChangeAmountSale() {
    $(document).on('change', ".estimate-tool-monthly-budget-sales .amount-of-month", (e) => {
      this.updateAmountCurrencyHintWhenChangeAmount(e)
      this.handleCalculatorSaleTotal()
    })
  }

  handleChangeCapSale() {
    $(document).on('change', ".estimate-tool-monthly-budget-sales .cap-of-month", (e) => {
      this.handleCalculatorSaleTotal()
    })
  }

  handleChangePositionSale() {
    $(document).on('change', ".estimate-tool-monthly-budget-sales .monthly-sale-market", (e) => {
      const rate = $(e.target).find('option:selected').data('monthRate')
      $(e.target).closest('.nested-fields').find(".amount-of-month").val(rate)
      this.updateAmountCurrencyHintWhenChangePositionSelect(e, rate)
      this.handleCalculatorSaleTotal()
    })
  }

  handleChangeAmountCost() {
    $(document).on('change', ".estimate-tool-monthly-budget-cost .amount-of-month", (e) => {
      this.updateAmountCurrencyHintWhenChangeAmount(e)
      this.handleCalculatorCostTotal()
      this.handleChangeOperationCost()
    })
  }

  handleChangeCapCost() {
    $(document).on('change', ".estimate-tool-monthly-budget-cost .cap-of-month", (e) => {
      this.handleCalculatorCostTotal()
    })
  }

  handleChangePositionCost() {
    this.handleChangeOperationCost()
    $(document).on('change', ".estimate-tool-monthly-budget-cost .monthly-cost-market", (e) => {
      const rate = $(e.target).find('option:selected').data('monthRate')
      $(e.target).closest('.nested-fields').find(".amount-of-month").val(rate)
      this.updateAmountCurrencyHintWhenChangePositionSelect(e, rate)
      this.handleCalculatorCostTotal()
      // this.handleChangeOperationCost()
    })
  }

  handleCurrencyMonthlyBudget() {
    $(document).on('change', ".estimate-tool-monthly-budget-sales .monthly-market-currency", (e) => {
      let collectionMarket = $(e.target).find('option:selected').data('marketCollection')
      let selectMarket = $(e.target).closest('.nested-fields').find(".monthly-sale-market")

      selectMarket.empty().select2();

      let pleaseSelect = [{id: "", text: "Please select .."}]
      selectMarket.select2({
        data: [...pleaseSelect, ...collectionMarket]
      });
      $(e.target).closest('.nested-fields').find(".amount-of-month").val(0)
      this.handleCalculatorSaleTotal()
    })
  }

  handleCalculatorSaleTotal() {
    let amountTotal = 0
    let capTotal = 0
    const projectCurrencySymbol = $('.estimation-tool').data('projectCurrencySymbol')
    const currencySymbolConvert = $('.estimation-tool').data('currencySymbolConvert')

    $('.estimate-tool-monthly-budget-sales').data('quantityOfMonth').forEach((monthAndYear) => {
      let amountOfMonth = 0
      let capOfMonth = 0
      $('.estimate-tool-monthly-budget-sales').find('.nested-fields:not([style*="display: none"])').each((index, element) => {
        let amount = parseFloat($(element).find(".amount-of-month-" + monthAndYear).val()) || 0
        let cap = parseFloat($(element).find(".cap-of-month-" + monthAndYear).val()) || 0
        amountOfMonth += amount*cap
        capOfMonth += cap
      })
      $('.sum-budget-sale-of-month-' + monthAndYear).html(projectCurrencySymbol + this.addDotForNumber(amountOfMonth.toFixed(0)))
      $('.sum-cap-sale-of-month-' + monthAndYear).html(this.addDotForNumber(capOfMonth.toFixed(0)))
      amountTotal += amountOfMonth
      capTotal += capOfMonth
    })
    $('.total-sale-budget').html(projectCurrencySymbol + this.addDotForNumber(amountTotal.toFixed(0)))
    $('.total-sale-cap').html(this.addDotForNumber(capTotal.toFixed(0)))
    $('.total-sale-budget-of-project').html(projectCurrencySymbol + this.addDotForNumber(amountTotal.toFixed(0)))
    $('.total-sale-budget-of-project').data('totalSaleBudgetOfProject', amountTotal)

    $('.total-sale-aud-budget').html(currencySymbolConvert + this.addDotForNumber(this.convert_currency(amountTotal).toFixed(0)))
    $('.total-sale-aud-budget').data('totalSaleAudBudget', this.convert_currency(amountTotal).toFixed(0))
    $('.total-sale-jpy-budget').html(currencySymbolConvert + this.addDotForNumber(this.convert_currency(amountTotal).toFixed(0)))
    $('.total-sale-jpy-budget').data('totalSaleJpyBudget', this.convert_currency(amountTotal).toFixed(0))

    this.handleChangeOperationCost()
    this.handleCalculatorProfit()
  }

  handleCalculatorProfit() {
    const projectCurrencySymbol = $('.estimation-tool').data('projectCurrencySymbol')
    const currencySymbolConvert = $('.estimation-tool').data('currencySymbolConvert')
    const budgetSale = $('.total-sale-budget-of-project').data("totalSaleBudgetOfProject")
    const budgetCost = $('.total-cost-budget-of-project').data("totalCostBudgetOfProject")
    const budgetOtherSale = $('.total-sale-jpy-budget').data("totalSaleJpyBudget")
    const budgetOtherCost = $('.total-cost-jpy-budget').data("totalCostJpyBudget")

    $('.gross-profit-project').html(projectCurrencySymbol + this.addDotForNumber((budgetSale - budgetCost).toFixed(0)))
    $('.gross-profit-aud').html(currencySymbolConvert + this.addDotForNumber((budgetOtherSale - budgetOtherCost).toFixed(0)))
    $('.gross-profit-jpy').html(currencySymbolConvert + this.addDotForNumber((budgetOtherSale - budgetOtherCost).toFixed(0)))
    $('.gross-profit-margin').html(((budgetSale - budgetCost)*100/budgetSale || 0).toFixed(1) + "%")

    let totalOperationCost = 0
    $('.estimate-tool-monthly-operation-cost .operation-cost').each((index, element) => {
      totalOperationCost += parseFloat($(element).val())
    })

    const profit = budgetSale - budgetCost - totalOperationCost
    $('.net-profit-project').html(projectCurrencySymbol + this.addDotForNumber(profit.toFixed(0)))
    $('.net-profit-aud').html(currencySymbolConvert + this.addDotForNumber(this.convert_currency(profit).toFixed(0)))
    $('.net-profit-jpy').html(currencySymbolConvert + this.addDotForNumber(this.convert_currency(profit).toFixed(0)))
    $('.net-profit-margin').html((profit*100/budgetSale || 0).toFixed(1) + "%")
  }

  handleCalculatorCostTotal() {
    let amountTotal = 0
    let capTotal = 0
    const projectCurrencySymbol = $('.estimation-tool').data('projectCurrencySymbol')
    const currencySymbolConvert = $('.estimation-tool').data('currencySymbolConvert')

    $('.estimate-tool-monthly-budget-cost').data('quantityOfMonth').forEach((monthAndYear) => {
      let amountOfMonth = 0
      let capOfMonth = 0
      $('.estimate-tool-monthly-budget-cost').find('.nested-fields:not([style*="display: none"])').each((index, element) => {
        let amount = parseFloat($(element).find(".amount-of-month-" + monthAndYear).val()) || 0
        let cap = parseFloat($(element).find(".cap-of-month-" + monthAndYear).val()) || 0
        amountOfMonth += amount*cap
        capOfMonth += cap
      })
      $('.sum-budget-cost-of-month-' + monthAndYear).html(projectCurrencySymbol + this.addDotForNumber(amountOfMonth.toFixed(0)))
      $('.sum-cap-cost-of-month-' + monthAndYear).html(this.addDotForNumber(capOfMonth.toFixed(0)))
      $('.operation-cost-' + monthAndYear).data("budgetCostOfMonth", amountOfMonth)
      amountTotal += amountOfMonth
      capTotal += capOfMonth
    })
    $('.total-cost-budget').html(projectCurrencySymbol + this.addDotForNumber(amountTotal.toFixed(0)))
    $('.total-cost-cap').html(this.addDotForNumber(capTotal.toFixed(0)))
    $('.total-cost-budget-of-project').html(projectCurrencySymbol + this.addDotForNumber(amountTotal.toFixed(0)))
    $('.total-cost-budget-of-project').data('totalCostBudgetOfProject', amountTotal)
    $('.total-cost-aud-budget').html(currencySymbolConvert + this.addDotForNumber(this.convert_currency(amountTotal).toFixed(0)))
    $('.total-cost-aud-budget').data('totalCostAudBudget', this.convert_currency(amountTotal).toFixed(0))
    $('.total-cost-jpy-budget').html(currencySymbolConvert + this.addDotForNumber(this.convert_currency(amountTotal).toFixed(0)))
    $('.total-cost-jpy-budget').data('totalCostJpyBudget', this.convert_currency(amountTotal).toFixed(0))
    this.handleChangeOperationCost()
    this.handleCalculatorProfit()
  }

  addDotForNumber(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  updateAmountCurrencyHintWhenChangeAmount(e) {
    const currencySymbolConvert = $('.estimation-tool').data('currencySymbolConvert')
    const currencyNameConvert = $('.estimation-tool').data('currencyNameConvert')
    const amount = $(e.target).val()
    $(e.target).closest('td').find('small.amount').html("To " + currencyNameConvert + ": " + currencySymbolConvert + this.addDotForNumber(this.convert_currency(amount).toFixed(0)))
  }

  updateAmountCurrencyHintWhenChangePositionSelect(e, amount) {
    const currencySymbolConvert = $('.estimation-tool').data('currencySymbolConvert')
    const currencyNameConvert = $('.estimation-tool').data('currencyNameConvert')
    $(e.target).closest('tr').find('small.amount').html("To " + currencyNameConvert + ": " + currencySymbolConvert + this.addDotForNumber(this.convert_currency(amount).toFixed(0)))
  }

  convert_currency(amount) {
    const currencyExchangeRateConvert = $('.estimation-tool').data('currencyExchangeRateConvert')
    const projectExchangeRate = $('.estimation-tool').data('projectExchangeRate')
    if ( currencyExchangeRateConvert == 0 || projectExchangeRate == 0 ) {
      return 0
    } else {
      return (amount/projectExchangeRate*currencyExchangeRateConvert)
    }
  }
}

export default EstimateToolsMonthlyBase
