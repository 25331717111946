class StaffLeaveRequest {
  constructor(){
  }

  bindEvents() {
    this.handleChangeStartDate()
    this.handleRemoveRaw()
    this.initDateRequest()
    this.handleDateTimePicker()
  }
  initDateRequest() {
    $('#date-request').val(moment(new Date()).format("DD-MM-YYYY"));
  }
  handleChangeStartDate() {
    $(document).on('dp.change',".start_date", (e) => {
      const startDate = moment(e.date.format('DD-MM-YYYY HH:00'), 'DD-MM-YYYY HH:00')
      const finishDate = moment($(e.currentTarget).closest('.row').find(".finish_date").val(), 'DD-MM-YYYY HH:00')
      const totalHours = this.handleCalculatorLeaveRequestTimeLine(startDate, finishDate)
      const numberWeekend = this.countWeekendCountBetweenDates(startDate, finishDate)
      $(e.currentTarget).closest('.row').find(".hours-off").val(totalHours - numberWeekend * 7.5)
      this.calculatorTotalHour()
    })

    $(document).on('dp.change',".finish_date", (e) => {
      const finishDate = moment(e.date.format('DD-MM-YYYY HH:00'), 'DD-MM-YYYY HH:00')
      const startDate = moment($(e.currentTarget).closest('.row').find(".start_date").val(), 'DD-MM-YYYY HH:00')
      const totalHours = this.handleCalculatorLeaveRequestTimeLine(startDate, finishDate)
      const numberWeekend = this.countWeekendCountBetweenDates(startDate, finishDate)
      $(e.currentTarget).closest('.row').find(".hours-off").val(totalHours - numberWeekend * 7.5)
      this.calculatorTotalHour()
    })
  }

  calculatorTotalHour() {
    let total = 0
    $('#staff-leave-request').find('.nested-fields:not([style*="display: none"])').each((index, element) => {
      const amount = $(element).find(".hours-off").val()
      total+=parseFloat(amount || 0)
    })
    $('.total-hours-off').val(total)
  }

  handleRemoveRaw() {
    $('#staff-leave-request').on('cocoon:after-remove', (e, insertedItem, originalEvent) => {
      this.calculatorTotalHour()
    })
  }

  handleCalculatorLeaveRequestTimeLine(startDate, finishDate){
    const startDateChangeFormat = moment(startDate.format('MM-DD-YYYY'))
    const finishDateChangeFormat = moment(finishDate.format('MM-DD-YYYY'))
    const dateOff = finishDateChangeFormat.diff(startDateChangeFormat, 'days')

    const startDateHour = moment(startDate, 'DD-MM-YYYY HH:00').hour()
    const finishDateHour = moment(finishDate, 'DD-MM-YYYY HH:00').hour()

    const timeLeaveAtFirstDate = this.calculatorTimeLeave((18 - startDateHour))
    const timeLeaveAtFinishDate = this.calculatorTimeLeave((finishDateHour - 10))
    let timeLeave = 0

    switch(dateOff) {
      case 0:
        timeLeave = this.calculatorTimeLeave(finishDateHour-startDateHour)
        break;

      case 1:
        timeLeave = timeLeaveAtFirstDate + timeLeaveAtFinishDate
        break;

      default:
        timeLeave =  Math.max(0, (dateOff-1)*7.5 + timeLeaveAtFirstDate + timeLeaveAtFinishDate)
    }

    return (timeLeave)
  }

  calculatorTimeLeave(hour) {
    hour = Math.max(0, hour)
    if (hour == 8) {
      hour = 7.5
    }
    return hour
  }

  countWeekendCountBetweenDates(startDate, finishDate) {
    startDate.subtract(1, "day");
    const dateOff = finishDate.diff(startDate, 'days')
    let i = 0;
    let weekendCount = 0;
    while (i < dateOff && startDate < finishDate) {
      startDate.add(1, "day");
      i++;
      if (startDate.day() == 0 || startDate.day() == 6) {
        weekendCount++
      }

    }
    return weekendCount;
  }

  handleDateTimePicker() {
    $(".datetimepicker").map((index, input) => {
      var dateCurrent = new Date($(input).data("date"));
      $(input).datetimepicker({
        icons: {
          time: 'fas fa-clock'
        },
        date: dateCurrent,
        format: "DD-MM-YYYY HH:00",
        enabledHours:[10,11,12,13,14,15,16,17,18],
        daysOfWeekDisabled: [0,6]
      });
    })

    $(document).ready(function(){
      $('#sazae-internal-system-app').on('cocoon:after-insert', (e, insertedItem) => {
        $(".datetimepicker").datetimepicker({
          icons: {
            time: 'fas fa-clock'
          },
          format: "DD-MM-YYYY HH:00",
          enabledHours:[10,11,12,13,14,15,16,17,18],
          daysOfWeekDisabled: [0,6]
        });
      });
    });
  }
}

export default StaffLeaveRequest
