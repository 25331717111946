class AccountCode {
  constructor(){
  }

  bindEvents() {
    this.handleSelectDepartment()
  }

  handleSelectDepartment() {
    $('.department-select').on('change', (e) => {
      if ($('.department-select').data('is-edit-account-code-and-refresh-page')) {
        $('.department-select').data('is-edit-account-code-and-refresh-page', false)
      } else {
        $('.account-codes-wrapper .select-department').empty().select2();
        let pleaseSelect = [{id: "", text: "Please select .."}]
        if ($('.department-select').find('option:selected').data('department-collection')) {
          $('.select-department').select2({
            data: [...pleaseSelect, ...$('.department-select').find('option:selected').data('department-collection')]
          });
        }
      }
    }).trigger('change')
  }
}

export default AccountCode
