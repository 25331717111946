// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import * as lodash from 'lodash'
window.jQuery = $
window.$ = $
window.lodash = lodash;
window.Routes = require('routes.js.erb')
window.moment = require("moment")

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("popper.js")
require("bootstrap")
require("bootstrap-datepicker")
require("select2/dist/js/select2")
require("jquery.nicescroll")
require("moment")
require("@nathanvda/cocoon")
require("javascripts/bootstrap-datetimepicker.min")

require("javascripts/stisla_template/stisla")
require("javascripts/stisla_template/scripts")
require("javascripts/stisla_template/custom")

import appMessages from './pages/components/app_messages';
import General from './general';
import Staff from './pages/staff';
import Capacity from './pages/capacity';
import StaffLeaveRequest from './pages/staff_leave_request';
import Project from './pages/project';
import StaffHoliday from './pages/staff_holiday';
import MonthlyOffsetReports from './pages/monthly_offset_reports';
import EstimateToolsProjectBase from './pages/estimate_tools_project_base';
import EstimateToolsMonthlyBase from './pages/estimate_tools_monthly_base';
import EstimateTools from './pages/estimate_tools';
import Lead from './pages/lead';
import GeneralExpenseClaim from './pages/general_expense_claim';
import TravelAllowanceClaim from './pages/travel_allowance_claim';
import Customer from './pages/customer';
import ResourceManagement from './pages/resource_management';
import Home from './pages/home';
import ProjectTimeLog from './pages/project_time_log';
import ReportPL from './pages/report_pl';
import AccountCode from './pages/account_code';
import OperationSplit from './pages/operation_split';
import DepartmentStaffCostRatio from './pages/department_staff_cost_ratio';
import AccountOperationCost from './pages/account_operation_cost';
import StaffRate from './pages/staff_rate';

import Chart from 'chart.js/auto';
window.Chart = Chart;

window.appMessages = appMessages

$(() => {
  const bodyData = $('body').data();

  const general = new General()
  general.bindEvents()

  const staff = new Staff()
  if (bodyData.controller == 'staffs') {
    staff.bindEvents()
  }

  const capacity = new Capacity()
  if (bodyData.controller == 'capacities') {
    capacity.bindEvents()
  }

  const lead = new Lead()
  if (bodyData.controller == 'leads') {
    lead.bindEvents()
  }

  const staffLeaveRequest = new StaffLeaveRequest()
  if (bodyData.controller == 'staff_leave_requests') {
    staffLeaveRequest.bindEvents()
  }

  const staffHoliday = new StaffHoliday()
  if (bodyData.controller == 'staff_holidays') {
    staffHoliday.bindEvents()
  }

  const project = new Project()
  if (bodyData.controller == 'projects') {
    project.bindEvents()
  }

  const MonthlyOffsetReport = new MonthlyOffsetReports()
  if (bodyData.controller == 'monthly_offset_reports') {
    MonthlyOffsetReport.bindEvents()
  }

  const estimateToolsProjectBase = new EstimateToolsProjectBase()
  const estimateToolsMonthlyBase = new EstimateToolsMonthlyBase()
  const estimateTools = new EstimateTools()

  if (bodyData.controller == 'estimate_tools') {
    estimateToolsProjectBase.bindEvents()
    estimateTools.bindEvents()
    if (bodyData.action == 'budget') {
      estimateToolsMonthlyBase.bindEvents()
    }
  }

  const generalExpenseClaim = new GeneralExpenseClaim()
  if (bodyData.controller == 'general_expense_claims') {
    generalExpenseClaim.bindEvents()
  }

  const travelAllowanceClaim = new TravelAllowanceClaim()
  if (bodyData.controller == 'travel_allowance_claims') {
    travelAllowanceClaim.bindEvents()
  }

  const customer = new Customer()
  if (bodyData.controller == 'customers') {
    customer.bindEvents()
  }

  const home = new Home()
  if (bodyData.controller == 'home') {
    home.bindEvents()
  }

  const resourceManagement = new ResourceManagement()
  if (bodyData.controller == 'resource_managements') {
    resourceManagement.bindEvents()
  }

  const projectTimeLog = new ProjectTimeLog()
  if (bodyData.controller == 'project_time_logs') {
    projectTimeLog.bindEvents()
  }

  const reportPL = new ReportPL()
  if (bodyData.controller == 'report_pl') {
    reportPL.bindEvents()
  }

  const accountCode = new AccountCode()
  if (bodyData.controller == 'account_codes') {
    accountCode.bindEvents()
  }

  const operationSplit = new OperationSplit()
  if (bodyData.controller == 'operation_splits') {
    operationSplit.bindEvents()
  }

  const departmentStaffCostRatio = new DepartmentStaffCostRatio()
  if (bodyData.controller == 'department_staff_cost_ratios') {
    departmentStaffCostRatio.bindEvents()
  }

  const accountOperationCost = new AccountOperationCost()
  if (bodyData.controller == 'account_operation_costs') {
    accountOperationCost.bindEvents()
  }

  const staffRate = new StaffRate()
  if (bodyData.controller == 'staff_rates') {
    staffRate.bindEvents()
  }
})
