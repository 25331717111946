/**
 * File generated by js-routes 2.2.3
 * Based on Rails 6.1.6 routes of SazaeInternalSystem::Application
 */
const __jsr = (() => {
    const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
    let NodeTypes;
    (function (NodeTypes) {
        NodeTypes[NodeTypes["GROUP"] = 1] = "GROUP";
        NodeTypes[NodeTypes["CAT"] = 2] = "CAT";
        NodeTypes[NodeTypes["SYMBOL"] = 3] = "SYMBOL";
        NodeTypes[NodeTypes["OR"] = 4] = "OR";
        NodeTypes[NodeTypes["STAR"] = 5] = "STAR";
        NodeTypes[NodeTypes["LITERAL"] = 6] = "LITERAL";
        NodeTypes[NodeTypes["SLASH"] = 7] = "SLASH";
        NodeTypes[NodeTypes["DOT"] = 8] = "DOT";
    })(NodeTypes || (NodeTypes = {}));
    const isBrowser = typeof window !== "undefined";
    const ModuleReferences = {
        CJS: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                module.exports = routes;
            },
            isSupported() {
                return typeof module === "object";
            },
        },
        AMD: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                define([], function () {
                    return routes;
                });
            },
            isSupported() {
                return typeof define === "function" && !!define.amd;
            },
        },
        UMD: {
            define(routes) {
                if (ModuleReferences.AMD.isSupported()) {
                    ModuleReferences.AMD.define(routes);
                }
                else {
                    if (ModuleReferences.CJS.isSupported()) {
                        try {
                            ModuleReferences.CJS.define(routes);
                        }
                        catch (error) {
                            if (error.name !== "TypeError")
                                throw error;
                        }
                    }
                }
            },
            isSupported() {
                return (ModuleReferences.AMD.isSupported() ||
                    ModuleReferences.CJS.isSupported());
            },
        },
        ESM: {
            define() {
                // Module can only be defined using ruby code generation
            },
            isSupported() {
                // Its impossible to check if "export" keyword is supported
                return true;
            },
        },
        NIL: {
            define() {
                // Defined using const __jsr = 
            },
            isSupported() {
                return true;
            },
        },
        DTS: {
            // Acts the same as ESM
            define(routes) {
                ModuleReferences.ESM.define(routes);
            },
            isSupported() {
                return ModuleReferences.ESM.isSupported();
            },
        },
    };
    class ParametersMissing extends Error {
        constructor(...keys) {
            super(`Route missing required keys: ${keys.join(", ")}`);
            this.keys = keys;
            Object.setPrototypeOf(this, Object.getPrototypeOf(this));
            this.name = ParametersMissing.name;
        }
    }
    const UriEncoderSegmentRegex = /[^a-zA-Z0-9\-._~!$&'()*+,;=:@]/g;
    const ReservedOptions = [
        "anchor",
        "trailing_slash",
        "subdomain",
        "host",
        "port",
        "protocol",
    ];
    class UtilsClass {
        constructor() {
            this.configuration = {
                prefix: "",
                default_url_options: {},
                special_options_key: "_options",
                serializer: null || this.default_serializer.bind(this),
            };
        }
        default_serializer(value, prefix) {
            if (this.is_nullable(value)) {
                return "";
            }
            if (!prefix && !this.is_object(value)) {
                throw new Error("Url parameters should be a javascript hash");
            }
            prefix = prefix || "";
            const result = [];
            if (this.is_array(value)) {
                for (const element of value) {
                    result.push(this.default_serializer(element, prefix + "[]"));
                }
            }
            else if (this.is_object(value)) {
                for (let key in value) {
                    if (!hasProp(value, key))
                        continue;
                    let prop = value[key];
                    if (this.is_nullable(prop) && prefix) {
                        prop = "";
                    }
                    if (this.is_not_nullable(prop)) {
                        if (prefix) {
                            key = prefix + "[" + key + "]";
                        }
                        result.push(this.default_serializer(prop, key));
                    }
                }
            }
            else {
                if (this.is_not_nullable(value)) {
                    result.push(encodeURIComponent(prefix) + "=" + encodeURIComponent("" + value));
                }
            }
            return result.join("&");
        }
        serialize(object) {
            return this.configuration.serializer(object);
        }
        extract_options(number_of_params, args) {
            const last_el = args[args.length - 1];
            if ((args.length > number_of_params && last_el === 0) ||
                (this.is_object(last_el) &&
                    !this.looks_like_serialized_model(last_el))) {
                if (this.is_object(last_el)) {
                    delete last_el[this.configuration.special_options_key];
                }
                return {
                    args: args.slice(0, args.length - 1),
                    options: last_el,
                };
            }
            else {
                return { args, options: {} };
            }
        }
        looks_like_serialized_model(object) {
            return (this.is_object(object) &&
                !(this.configuration.special_options_key in object) &&
                ("id" in object || "to_param" in object || "toParam" in object));
        }
        path_identifier(object) {
            const result = this.unwrap_path_identifier(object);
            return this.is_nullable(result) || result === false ? "" : "" + result;
        }
        unwrap_path_identifier(object) {
            let result = object;
            if (!this.is_object(object)) {
                return object;
            }
            if ("to_param" in object) {
                result = object.to_param;
            }
            else if ("toParam" in object) {
                result = object.toParam;
            }
            else if ("id" in object) {
                result = object.id;
            }
            else {
                result = object;
            }
            return this.is_callable(result) ? result.call(object) : result;
        }
        partition_parameters(parts, required_params, default_options, call_arguments) {
            // eslint-disable-next-line prefer-const
            let { args, options } = this.extract_options(parts.length, call_arguments);
            if (args.length > parts.length) {
                throw new Error("Too many parameters provided for path");
            }
            let use_all_parts = args.length > required_params.length;
            const parts_options = {
                ...this.configuration.default_url_options,
            };
            for (const key in options) {
                const value = options[key];
                if (!hasProp(options, key))
                    continue;
                use_all_parts = true;
                if (parts.includes(key)) {
                    parts_options[key] = value;
                }
            }
            options = {
                ...this.configuration.default_url_options,
                ...default_options,
                ...options,
            };
            const keyword_parameters = {};
            const query_parameters = {};
            for (const key in options) {
                if (!hasProp(options, key))
                    continue;
                const value = options[key];
                if (this.is_reserved_option(key)) {
                    keyword_parameters[key] = value;
                }
                else {
                    if (!this.is_nullable(value) &&
                        (value !== default_options[key] || required_params.includes(key))) {
                        query_parameters[key] = value;
                    }
                }
            }
            const route_parts = use_all_parts ? parts : required_params;
            let i = 0;
            for (const part of route_parts) {
                if (i < args.length) {
                    const value = args[i];
                    if (!hasProp(parts_options, part)) {
                        query_parameters[part] = value;
                        ++i;
                    }
                }
            }
            return { keyword_parameters, query_parameters };
        }
        build_route(parts, required_params, default_options, route, absolute, args) {
            const { keyword_parameters, query_parameters, } = this.partition_parameters(parts, required_params, default_options, args);
            const missing_params = required_params.filter((param) => !hasProp(query_parameters, param) ||
                this.is_nullable(query_parameters[param]));
            if (missing_params.length) {
                throw new ParametersMissing(...missing_params);
            }
            let result = this.get_prefix() + this.visit(route, query_parameters);
            if (keyword_parameters.trailing_slash) {
                result = result.replace(/(.*?)[/]?$/, "$1/");
            }
            const url_params = this.serialize(query_parameters);
            if (url_params.length) {
                result += "?" + url_params;
            }
            result += keyword_parameters.anchor
                ? "#" + keyword_parameters.anchor
                : "";
            if (absolute) {
                result = this.route_url(keyword_parameters) + result;
            }
            return result;
        }
        visit(route, parameters, optional = false) {
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return this.visit(route[1], parameters, true);
                case NodeTypes.CAT:
                    return this.visit_cat(route, parameters, optional);
                case NodeTypes.SYMBOL:
                    return this.visit_symbol(route, parameters, optional);
                case NodeTypes.STAR:
                    return this.visit_globbing(route[1], parameters, true);
                case NodeTypes.LITERAL:
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        is_not_nullable(object) {
            return !this.is_nullable(object);
        }
        is_nullable(object) {
            return object === undefined || object === null;
        }
        visit_cat(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, left, right], parameters, optional) {
            const left_part = this.visit(left, parameters, optional);
            let right_part = this.visit(right, parameters, optional);
            if (optional &&
                ((this.is_optional_node(left[0]) && !left_part) ||
                    (this.is_optional_node(right[0]) && !right_part))) {
                return "";
            }
            // if left_part ends on '/' and right_part starts on '/'
            if (left_part[left_part.length - 1] === "/" && right_part[0] === "/") {
                // strip slash from right_part
                // to prevent double slash
                right_part = right_part.substring(1);
            }
            return left_part + right_part;
        }
        visit_symbol(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, key], parameters, optional) {
            const value = this.path_identifier(parameters[key]);
            delete parameters[key];
            if (value.length) {
                return this.encode_segment(value);
            }
            if (optional) {
                return "";
            }
            else {
                throw new ParametersMissing(key);
            }
        }
        encode_segment(segment) {
            return segment.replace(UriEncoderSegmentRegex, (str) => encodeURIComponent(str));
        }
        is_optional_node(node) {
            return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
        }
        build_path_spec(route, wildcard = false) {
            let key;
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return "(" + this.build_path_spec(route[1]) + ")";
                case NodeTypes.CAT:
                    return (this.build_path_spec(route[1]) + this.build_path_spec(route[2]));
                case NodeTypes.STAR:
                    return this.build_path_spec(route[1], true);
                case NodeTypes.SYMBOL:
                    key = route[1];
                    if (wildcard) {
                        return (key.startsWith("*") ? "" : "*") + key;
                    }
                    else {
                        return ":" + key;
                    }
                    break;
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                case NodeTypes.LITERAL:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        visit_globbing(route, parameters, optional) {
            const key = route[1];
            let value = parameters[key];
            delete parameters[key];
            if (this.is_nullable(value)) {
                return this.visit(route, parameters, optional);
            }
            if (this.is_array(value)) {
                value = value.join("/");
            }
            const result = this.path_identifier(value);
            return false
                ? result
                : encodeURI(result);
        }
        get_prefix() {
            const prefix = this.configuration.prefix;
            return prefix.match("/$")
                ? prefix.substring(0, prefix.length - 1)
                : prefix;
        }
        route(parts_table, route_spec, absolute = false) {
            const required_params = [];
            const parts = [];
            const default_options = {};
            for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
                parts.push(part);
                if (required) {
                    required_params.push(part);
                }
                if (this.is_not_nullable(value)) {
                    default_options[part] = value;
                }
            }
            const result = (...args) => {
                return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
            };
            result.requiredParams = () => required_params;
            result.toString = () => {
                return this.build_path_spec(route_spec);
            };
            return result;
        }
        route_url(route_defaults) {
            const hostname = route_defaults.host || this.current_host();
            if (!hostname) {
                return "";
            }
            const subdomain = route_defaults.subdomain
                ? route_defaults.subdomain + "."
                : "";
            const protocol = route_defaults.protocol || this.current_protocol();
            let port = route_defaults.port ||
                (!route_defaults.host ? this.current_port() : undefined);
            port = port ? ":" + port : "";
            return protocol + "://" + subdomain + hostname + port;
        }
        current_host() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname)) || "";
        }
        current_protocol() {
            var _a, _b;
            return ((isBrowser && ((_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.protocol) === null || _b === void 0 ? void 0 : _b.replace(/:$/, ""))) || "http");
        }
        current_port() {
            var _a;
            return (isBrowser && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.port)) || "";
        }
        is_object(value) {
            return (typeof value === "object" &&
                Object.prototype.toString.call(value) === "[object Object]");
        }
        is_array(object) {
            return object instanceof Array;
        }
        is_callable(object) {
            return typeof object === "function" && !!object.call;
        }
        is_reserved_option(key) {
            return ReservedOptions.includes(key);
        }
        configure(new_config) {
            this.configuration = { ...this.configuration, ...new_config };
            return this.configuration;
        }
        config() {
            return { ...this.configuration };
        }
        is_module_supported(name) {
            return ModuleReferences[name].isSupported();
        }
        ensure_module_supported(name) {
            if (!this.is_module_supported(name)) {
                throw new Error(`${name} is not supported by runtime`);
            }
        }
        define_module(name, module) {
            this.ensure_module_supported(name);
            ModuleReferences[name].define(module);
        }
    }
    const Utils = new UtilsClass();
    // We want this helper name to be short
    const __jsr = {
        r(parts_table, route_spec, absolute) {
            return Utils.route(parts_table, route_spec, absolute);
        },
    };
    const result = {
        ...__jsr,
        configure: (config) => {
            return Utils.configure(config);
        },
        config: () => {
            return Utils.config();
        },
        serialize: (object) => {
            return Utils.serialize(object);
        },
        ...{},
    };
    Utils.define_module("ESM", result);
    return result;
})();
export const configure = __jsr.configure;

export const config = __jsr.config;

export const serialize = __jsr.serialize;

/**
 * Generates rails route to
 * /absence_types/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const absence_type_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"absence_types"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /absence_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const absence_types_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"absence_types"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /assignments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const assignment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"assignments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /assignments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const assignments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"assignments"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /estimate_tools/:id/budget(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const budget_estimate_tool_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"estimate_tools"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"budget"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /capabilities/bulk_update(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulk_update_capabilities_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"capabilities"],[2,[7,"/"],[2,[6,"bulk_update"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /capabilities/by_month(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const by_month_capabilities_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"capabilities"],[2,[7,"/"],[2,[6,"by_month"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /capabilities(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capabilities_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"capabilities"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /capabilities/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capability_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"capabilities"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /home/capacity_search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const capacity_search_home_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"home"],[2,[7,"/"],[2,[6,"capacity_search"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ckeditor
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ckeditor_path = __jsr.r({}, [2,[7,"/"],[6,"ckeditor"]]);

/**
 * Generates rails route to
 * /ckeditor/pictures(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ckeditor_pictures_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"ckeditor"]],[7,"/"]],[2,[6,"pictures"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /ckeditor/pictures/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ckeditor_picture_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"ckeditor"]],[7,"/"]],[2,[6,"pictures"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ckeditor/attachment_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ckeditor_attachment_files_path = __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"ckeditor"]],[7,"/"]],[2,[6,"attachment_files"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /ckeditor/attachment_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ckeditor_attachment_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"ckeditor"]],[7,"/"]],[2,[6,"attachment_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /companies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const companies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"companies"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /companies/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /company_resources/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const company_resource_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"company_resources"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /company_resources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const company_resources_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"company_resources"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /conversations/:mailbox_type/:id(.:format)
 * @param {any} mailbox_type
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const conversation_path = __jsr.r({"mailbox_type":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"conversations"],[2,[7,"/"],[2,[3,"mailbox_type"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /conversations/:mailbox_type/:conversation_id/messages/:id(.:format)
 * @param {any} mailbox_type
 * @param {any} conversation_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const conversation_message_path = __jsr.r({"mailbox_type":{"r":true},"conversation_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"conversations"],[2,[7,"/"],[2,[3,"mailbox_type"],[2,[7,"/"],[2,[3,"conversation_id"],[2,[7,"/"],[2,[6,"messages"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /conversations/:mailbox_type/:conversation_id/messages(.:format)
 * @param {any} mailbox_type
 * @param {any} conversation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const conversation_messages_path = __jsr.r({"mailbox_type":{"r":true},"conversation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"conversations"],[2,[7,"/"],[2,[3,"mailbox_type"],[2,[7,"/"],[2,[3,"conversation_id"],[2,[7,"/"],[2,[6,"messages"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /conversations/:mailbox_type(.:format)
 * @param {any} mailbox_type
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const conversations_path = __jsr.r({"mailbox_type":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"conversations"],[2,[7,"/"],[2,[3,"mailbox_type"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /currencies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const currencies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"currencies"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /currencies/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const currency_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"currencies"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /customers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /customers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customers"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /departments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const department_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"departments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /department_rates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const department_rate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"department_rates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /department_rates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const department_rates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"department_rates"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /departments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const departments_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"departments"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /users/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /divisions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const division_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"divisions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /divisions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const divisions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"divisions"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /customers/download(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_customers_path = __jsr.r({"format":{"d":"csv"}}, [2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /department_rates/download(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_department_rates_path = __jsr.r({"format":{"d":"csv"}}, [2,[7,"/"],[2,[6,"department_rates"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /hard_ware_assets/:hardware_type/download(.:format)
 * @param {any} hardware_type
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_hard_ware_assets_path = __jsr.r({"hardware_type":{"r":true},"format":{"d":"csv"}}, [2,[7,"/"],[2,[6,"hard_ware_assets"],[2,[7,"/"],[2,[3,"hardware_type"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /leads/download(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_leads_path = __jsr.r({"format":{"d":"csv"}}, [2,[7,"/"],[2,[6,"leads"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /project_pl/download(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_project_pl_index_path = __jsr.r({"format":{"d":"csv"}}, [2,[7,"/"],[2,[6,"project_pl"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /project_time_logs/download(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_project_time_logs_path = __jsr.r({"format":{"d":"csv"}}, [2,[7,"/"],[2,[6,"project_time_logs"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects/download(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_projects_path = __jsr.r({"format":{"d":"csv"}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /resource_managements/download(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_resource_managements_path = __jsr.r({"format":{"d":"csv"}}, [2,[7,"/"],[2,[6,"resource_managements"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /software_assets/download(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_software_assets_path = __jsr.r({"format":{"d":"csv"}}, [2,[7,"/"],[2,[6,"software_assets"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /staffs/download(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_staffs_path = __jsr.r({"format":{"d":"csv"}}, [2,[7,"/"],[2,[6,"staffs"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /absence_types/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_absence_type_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"absence_types"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /assignments/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_assignment_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"assignments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /capabilities/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_capability_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"capabilities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /companies/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /company_resources/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_company_resource_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"company_resources"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /conversations/:mailbox_type/:id/edit(.:format)
 * @param {any} mailbox_type
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_conversation_path = __jsr.r({"mailbox_type":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"conversations"],[2,[7,"/"],[2,[3,"mailbox_type"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /conversations/:mailbox_type/:conversation_id/messages/:id/edit(.:format)
 * @param {any} mailbox_type
 * @param {any} conversation_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_conversation_message_path = __jsr.r({"mailbox_type":{"r":true},"conversation_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"conversations"],[2,[7,"/"],[2,[3,"mailbox_type"],[2,[7,"/"],[2,[3,"conversation_id"],[2,[7,"/"],[2,[6,"messages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /currencies/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_currency_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"currencies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /customers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_customer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /departments/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_department_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"departments"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /department_rates/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_department_rate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"department_rates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /divisions/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_division_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"divisions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /estimate_tools/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_estimate_tool_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"estimate_tools"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /expense_account_codes/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_expense_account_code_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"expense_account_codes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /freee_invoices/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_freee_invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"freee_invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /hard_ware_assets/:hardware_type/:id/edit(.:format)
 * @param {any} hardware_type
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_hard_ware_asset_path = __jsr.r({"hardware_type":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"hard_ware_assets"],[2,[7,"/"],[2,[3,"hardware_type"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /leads/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_lead_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"leads"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /market/:market_type/:id/edit(.:format)
 * @param {any} market_type
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_market_path = __jsr.r({"market_type":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"market"],[2,[7,"/"],[2,[3,"market_type"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /methodology_resource_documents/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_methodology_resource_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"methodology_resource_documents"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /positions/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_position_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"positions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /profiles/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_profile_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_project_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /project_time_logs/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_project_time_log_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"project_time_logs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /sazae_resources/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_sazae_resource_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"sazae_resources"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /sazae_vendors/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_sazae_vendor_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"sazae_vendors"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /software_assets/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_software_asset_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"software_assets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /staffs/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_staff_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staffs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /staff_holidays/:team_type/:id/edit(.:format)
 * @param {any} team_type
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_staff_holiday_path = __jsr.r({"team_type":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staff_holidays"],[2,[7,"/"],[2,[3,"team_type"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /staff_leave_requests/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_staff_leave_request_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staff_leave_requests"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /staff_rates/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_staff_rate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staff_rates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /staff_roles/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_staff_role_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staff_roles"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /study_resources/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_study_resource_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"study_resources"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /supplier_companies/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_supplier_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"supplier_companies"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /vendor_staffs/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_vendor_staff_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"vendor_staffs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /estimate_tools/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const estimate_tool_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"estimate_tools"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /estimate_tools(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const estimate_tools_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"estimate_tools"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /expense_account_codes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const expense_account_code_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"expense_account_codes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /expense_account_codes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const expense_account_codes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"expense_account_codes"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /freee_invoices/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const freee_invoice_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"freee_invoices"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /general_expense_claims/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const general_expense_claim_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"general_expense_claims"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /general_expense_claims(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const general_expense_claims_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"general_expense_claims"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /get_freee_access_token_from_callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_freee_access_token_from_callback_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"get_freee_access_token_from_callback"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /get_xero_access_token_from_callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const get_xero_access_token_from_callback_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"get_xero_access_token_from_callback"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /hard_ware_assets/:hardware_type/:id(.:format)
 * @param {any} hardware_type
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const hard_ware_asset_path = __jsr.r({"hardware_type":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"hard_ware_assets"],[2,[7,"/"],[2,[3,"hardware_type"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /hard_ware_assets/:hardware_type(.:format)
 * @param {any} hardware_type
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const hard_ware_assets_path = __jsr.r({"hardware_type":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"hard_ware_assets"],[2,[7,"/"],[2,[3,"hardware_type"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /home(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const home_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"home"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /leads/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const lead_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"leads"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /leads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const leads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"leads"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /general_expense_claims/manage(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_general_expense_claims_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"general_expense_claims"],[2,[7,"/"],[2,[6,"manage"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /staff_leave_requests/manage(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_staff_leave_requests_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staff_leave_requests"],[2,[7,"/"],[2,[6,"manage"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /travel_allowance_claims/manage(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manage_travel_allowance_claims_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"travel_allowance_claims"],[2,[7,"/"],[2,[6,"manage"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /market/:market_type/:id(.:format)
 * @param {any} market_type
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const market_path = __jsr.r({"market_type":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"market"],[2,[7,"/"],[2,[3,"market_type"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /market/:market_type(.:format)
 * @param {any} market_type
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const markets_path = __jsr.r({"market_type":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"market"],[2,[7,"/"],[2,[3,"market_type"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /methodology_resource_documents/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const methodology_resource_document_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"methodology_resource_documents"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /methodology_resource_documents(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const methodology_resource_documents_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"methodology_resource_documents"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /absence_types/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_absence_type_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"absence_types"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /assignments/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_assignment_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"assignments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /capabilities/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_capability_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"capabilities"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /companies/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_company_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"companies"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /company_resources/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_company_resource_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"company_resources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /conversations/:mailbox_type/new(.:format)
 * @param {any} mailbox_type
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_conversation_path = __jsr.r({"mailbox_type":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"conversations"],[2,[7,"/"],[2,[3,"mailbox_type"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /conversations/:mailbox_type/:conversation_id/messages/new(.:format)
 * @param {any} mailbox_type
 * @param {any} conversation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_conversation_message_path = __jsr.r({"mailbox_type":{"r":true},"conversation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"conversations"],[2,[7,"/"],[2,[3,"mailbox_type"],[2,[7,"/"],[2,[3,"conversation_id"],[2,[7,"/"],[2,[6,"messages"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /currencies/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_currency_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"currencies"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /customers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_customer_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /departments/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_department_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"departments"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /department_rates/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_department_rate_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"department_rates"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /divisions/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_division_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"divisions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /estimate_tools/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_estimate_tool_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"estimate_tools"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /expense_account_codes/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_expense_account_code_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"expense_account_codes"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /general_expense_claims/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_general_expense_claim_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"general_expense_claims"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /hard_ware_assets/:hardware_type/new(.:format)
 * @param {any} hardware_type
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_hard_ware_asset_path = __jsr.r({"hardware_type":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"hard_ware_assets"],[2,[7,"/"],[2,[3,"hardware_type"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /leads/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_lead_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"leads"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /market/:market_type/new(.:format)
 * @param {any} market_type
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_market_path = __jsr.r({"market_type":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"market"],[2,[7,"/"],[2,[3,"market_type"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /methodology_resource_documents/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_methodology_resource_document_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"methodology_resource_documents"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /positions/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_position_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"positions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /profiles/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /projects/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_project_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /project_time_logs/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_project_time_log_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"project_time_logs"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_source_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /sazae_resources/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_sazae_resource_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sazae_resources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /sazae_vendors/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_sazae_vendor_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sazae_vendors"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /software_assets/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_software_asset_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"software_assets"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /staffs/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_staff_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staffs"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /staff_holidays/:team_type/new(.:format)
 * @param {any} team_type
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_staff_holiday_path = __jsr.r({"team_type":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staff_holidays"],[2,[7,"/"],[2,[3,"team_type"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /staff_leave_requests/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_staff_leave_request_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staff_leave_requests"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /staff_rates/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_staff_rate_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staff_rates"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /staff_roles/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_staff_role_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staff_roles"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /study_resources/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_study_resource_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"study_resources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /supplier_companies/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_supplier_company_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"supplier_companies"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /travel_allowance_claims/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_travel_allowance_claim_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"travel_allowance_claims"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /vendor_staffs/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_vendor_staff_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"vendor_staffs"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /notifications/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const notification_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"notifications"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /notifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const notifications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"notifications"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /positions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const position_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"positions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /positions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const positions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"positions"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /estimate_tools/preview(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preview_estimate_tools_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"estimate_tools"],[2,[7,"/"],[2,[6,"preview"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /profiles/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const profile_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"profiles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /profiles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const profiles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"profiles"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /projects/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const project_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /project_pl(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const project_pl_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"project_pl"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /project_time_logs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const project_time_log_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"project_time_logs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /project_time_logs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const project_time_logs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"project_time_logs"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /projects(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const projects_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"projects"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /customers/:id/push_data_to_xero(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const push_data_to_xero_customer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"customers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"push_data_to_xero"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /projects/:id/push_data_to_xero(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const push_data_to_xero_project_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"projects"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"push_data_to_xero"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_proxy_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
 * @param {any} inbound_email_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_reroute_path = __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"reroute"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_sources_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/direct_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_direct_uploads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"direct_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_key/*filename(.:format)
 * @param {any} encoded_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_disk_service_path = __jsr.r({"encoded_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailgun_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mailgun"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"mime"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_health_check_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/postmark/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_postmark_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"postmark"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/relay/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_relay_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"relay"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/sendgrid/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_sendgrid_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"sendgrid"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_proxy_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /project_time_logs/report_download(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const report_download_project_time_logs_path = __jsr.r({"format":{"d":"csv"}}, [2,[7,"/"],[2,[6,"project_time_logs"],[2,[7,"/"],[2,[6,"report_download"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /project_time_logs/report(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const report_project_time_logs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"project_time_logs"],[2,[7,"/"],[2,[6,"report"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /resource_managements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const resource_managements_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"resource_managements"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const root_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /sazae_resources/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sazae_resource_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"sazae_resources"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /sazae_resources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sazae_resources_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sazae_resources"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /sazae_vendors/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sazae_vendor_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"sazae_vendors"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /sazae_vendors(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sazae_vendors_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sazae_vendors"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /staffs/search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const search_staffs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staffs"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /general_expense_claims/:id/show_detail(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_detail_general_expense_claim_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"general_expense_claims"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"show_detail"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /staff_leave_requests/:id/show_detail(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_detail_staff_leave_request_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staff_leave_requests"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"show_detail"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /travel_allowance_claims/:id/show_detail(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_detail_travel_allowance_claim_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"travel_allowance_claims"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"show_detail"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /resource_managements/show_total(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_total_resource_managements_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"resource_managements"],[2,[7,"/"],[2,[6,"show_total"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /software_assets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const software_asset_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"software_assets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /software_assets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const software_assets_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"software_assets"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /staffs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const staff_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staffs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /staff_holidays/:team_type/:id(.:format)
 * @param {any} team_type
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const staff_holiday_path = __jsr.r({"team_type":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staff_holidays"],[2,[7,"/"],[2,[3,"team_type"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /staff_holidays/:team_type(.:format)
 * @param {any} team_type
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const staff_holidays_path = __jsr.r({"team_type":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staff_holidays"],[2,[7,"/"],[2,[3,"team_type"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /staff_leave_requests/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const staff_leave_request_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staff_leave_requests"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /staff_leave_requests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const staff_leave_requests_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staff_leave_requests"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /staff_rates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const staff_rate_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staff_rates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /staff_rates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const staff_rates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staff_rates"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /staff_roles/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const staff_role_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staff_roles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /staff_roles(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const staff_roles_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staff_roles"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /staffs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const staffs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staffs"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /study_resources/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const study_resource_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"study_resources"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /study_resources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const study_resources_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"study_resources"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /supplier_companies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_companies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"supplier_companies"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /supplier_companies/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const supplier_company_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"supplier_companies"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /project_pl/sync_data(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sync_data_project_pl_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"project_pl"],[2,[7,"/"],[2,[6,"sync_data"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /travel_allowance_claims/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const travel_allowance_claim_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"travel_allowance_claims"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /travel_allowance_claims(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const travel_allowance_claims_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"travel_allowance_claims"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /staffs/update_link(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_link_staffs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"staffs"],[2,[7,"/"],[2,[6,"update_link"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /estimate_tools/:id/update_project_department_rate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_project_department_rate_estimate_tool_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"estimate_tools"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_project_department_rate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_token(.:format)
 * @param {any} encoded_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_rails_disk_service_path = __jsr.r({"encoded_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /freee_invoices/update_serials(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_serials_freee_invoices_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"freee_invoices"],[2,[7,"/"],[2,[6,"update_serials"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /general_expense_claims/:id/update_status(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_status_general_expense_claim_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"general_expense_claims"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /staff_leave_requests/:id/update_status(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_status_staff_leave_request_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"staff_leave_requests"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /travel_allowance_claims/:id/update_status(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_status_travel_allowance_claim_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"travel_allowance_claims"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/auth/google_oauth2(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_google_oauth2_omniauth_authorize_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"google_oauth2"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/auth/google_oauth2/callback(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_google_oauth2_omniauth_callback_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"google_oauth2"],[2,[7,"/"],[2,[6,"callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /users/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/sign_in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /vendor_staffs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const vendor_staff_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"vendor_staffs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /vendor_staffs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const vendor_staffs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"vendor_staffs"],[1,[2,[8,"."],[3,"format"]]]]]);


