let $currentOperationSplit = null
class OperationSplit {
  constructor(){
  }

  bindEvents() {
    this.handleOpenForm()
  }

  handleOpenForm() {
    $(".set-operation-split").each((index, element) => {
      $(element).popover({
        placement: 'bottom',
        title : '<span>Set operation split</span>'+
        '<button type="button" id="close" class="close" onclick="$(&quot;.set-operation-split&quot;).popover(&quot;hide&quot;);">&times;</button>',
        html: true,
        content:  $(element).find("form"),
        sanitize: false,
      })
    })

    $('.set-operation-split').on('click', (e) => {
      $currentOperationSplit = $(e.currentTarget)
      $('.set-operation-split').popover('hide')
      $('.message-error-operation-split').html('')
      $(e.currentTarget).popover('show')
    });

    $(document).on('click', '.btn-save-operation-split', (e) => {
      const inputData = $(e.target).closest('form').serialize()
      const operationSplitId = $(e.target).closest('form').data('operationSplitId')
      let httpMethod = 'post'
      let urlOperationSplit = Routes.operation_splits_path()

      if (!!operationSplitId) {
        httpMethod = 'patch'
        urlOperationSplit = Routes.operation_split_path(operationSplitId)
      }

      $.ajax({
        url: urlOperationSplit,
        type: httpMethod,
        dataType: 'json',
        data: inputData,
        success: (data) => {
          if (data.errors) {
            $('.message-error-operation-split').html(data.errors)
          } else {
            $currentOperationSplit.html(data.percent)
            $(e.target).closest('form').data('operationSplitId', data.id)
            $('.set-operation-split').popover('hide')
            $(`.total-percent-with-division-id-${data.division_id}`).html(data.total_percent_of_division)
            $('#total-percent').html(data.total_percent)
          }
        },
        error: (err) => {
          $('.message-error-operation-split').html('Some thing went wrong !')
          error(err)
        }
      })
    })
  }
}

export default OperationSplit
