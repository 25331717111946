class Home {
  constructor(){
  }

  bindEvents() {
    this.handleMonthOfInput()
    this.handleInternalChart()
    this.handleOffshoreChart()
  }

  handleMonthOfInput() {
    $('.month-of-input').datepicker({
      format: 'mm/yyyy',
      viewMode: 'months',
      minViewMode: 'months',
      autoclose: true
    });
  }

  handleInternalChart() {
    const ctx = document.getElementById('internalChart')
    const capacities_data = $('#internalChart').data('staffs-capacities')
    const staffs_name = lodash.map(capacities_data, 'staff_name');
    const capacity_with_staffs = lodash.map(capacities_data, 'capacity');

    const myChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: staffs_name,
        datasets: [{
          label: 'Internal Staff capacity',
          data: capacity_with_staffs,
          backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(153, 102, 255, 0.2)', 'rgba(255, 159, 64, 0.2)'],
          borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)', 'rgba(255, 159, 64, 1)'],
          borderWidth: 1
        }]
      }
    })

    this.handleSearchInternalChart(myChart)
  }

  handleOffshoreChart() {
    const ctx = document.getElementById('offshoreChart')
    const capacities_data = $('#offshoreChart').data('staffs-capacities')
    const staffs_name = lodash.map(capacities_data, 'staff_name');
    const capacity_with_staffs = lodash.map(capacities_data, 'capacity');

    const myChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: staffs_name,
        datasets: [{
          label: 'Offshore Staff capacity',
          data: capacity_with_staffs,
          backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(153, 102, 255, 0.2)', 'rgba(255, 159, 64, 0.2)'],
          borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)', 'rgba(255, 159, 64, 1)'],
          borderWidth: 1
        }]
      }
    })

    this.handleSearchOffshoreChart(myChart)
  }

  handleSearchInternalChart(myChart) {
    const teamType = 'internal'
    let month = null
    let department = null

    $('.internal.month-of-input').on('change', (e) => {
      month = $(e.target).val()
      this.updateChart(month, department, teamType, myChart)
    })

    $('.internal.department-of-input').on('change', (e) => {
      department = $(e.target).val()
      this.updateChart(month, department, teamType, myChart)
    })
  }

  handleSearchOffshoreChart(myChart) {
    const teamType = 'offshore'
    let month = null
    let department = null

    $('.offshore.month-of-input').on('change', (e) => {
      month = $(e.target).val()
      this.updateChart(month, department, teamType, myChart)
    })

    $('.offshore.department-of-input').on('change', (e) => {
      department = $(e.target).val()
      this.updateChart(month, department, teamType, myChart)
    })
  }

  updateChart(month, department, teamType, myChart) {
    $.ajax({
      url: Routes.capacity_search_home_index_path(),
      type: 'get',
      dataType: 'json',
      data: {
        month: month,
        department: department,
        team_type: teamType
      },
      success: (data) => {
        const capacities_data = data['result']
        const staffs_name = lodash.map(capacities_data, 'staff_name')
        const capacity_with_staffs = lodash.map(capacities_data, 'capacity')

        myChart.data.labels = staffs_name
        myChart.data.datasets.forEach((dataset) => {
          dataset.data = capacity_with_staffs
        })
        myChart.update()
      }
    })
  }
}

export default Home
