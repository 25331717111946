class AccountOperationCost {
  constructor(){
  }

  bindEvents() {
    this.handleMonthOfInput()
  }

  handleMonthOfInput() {
    $('.month-of-input').datepicker({
      format: 'mm/yyyy',
      viewMode: 'months',
      minViewMode: 'months'
    });

    $(document).ready(function(){
      $('.month-of-input').datepicker();
      $('#sazae-internal-system-app').on('cocoon:after-insert', (e, insertedItem) => {
        $('.month-of-input').datepicker({
          format: 'mm/yyyy',
          viewMode: 'months',
          minViewMode: 'months'
        });
      });
    });
  }
}
export default AccountOperationCost
