class GeneralExpenseClaim {
  constructor(){
  }

  bindEvents() {
    this.handleChangeAmount()
    this.handleRemoveRaw()
  }

  handleChangeAmount() {
    $(document).on('change', ".general-expense-amount", (e) => {
      this.calculatorSubTotal()
    })
  }

  handleRemoveRaw() {
    $('.general_expense_claim_details').on('cocoon:after-remove', (e, insertedItem, originalEvent) => {
      this.calculatorSubTotal()
    })
  }

  calculatorSubTotal() {
    let sub_total = 0
    $('.general_expense_claim_details').find('.nested-fields:not([style*="display: none"])').each((index, element) => {
      const amount = $(element).find(".general-expense-amount").val()
      sub_total+=parseFloat(amount || 0)
    })
    $('.general-expense-sub-total').html(sub_total)
  }
}

export default GeneralExpenseClaim
