class StaffRate {
  constructor(){
  }

  bindEvents() {
    this.handleSelectedDepartment()
  }

  handleSelectedDepartment() {
    $('.department-select').on('change', (e) => {
      const staffId = $('.staff-position').find('option:selected').val()

      $('.staff-position').empty().select2();
      let pleaseSelect = [{id: "", text: "Please select .."}]
      if ($('.department-select').find('option:selected').data('staff-collection')) {
        $('.staff-position').select2({
          data: [...pleaseSelect, ...$('.department-select').find('option:selected').data('staff-collection')]
        });
      }

      $('.staff-position').val(staffId).select2()
    })
  }
}

export default StaffRate
