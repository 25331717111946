let $currentDepartmentStaffCostRatio = null
class DepartmentStaffCostRatio {
  constructor(){
  }

  bindEvents() {
    this.handleOpenForm()
  }

  handleOpenForm() {
    $(".set-department-staff-cost-ratio").each((index, element) => {
      $(element).popover({
        placement: 'bottom',
        title : '<span>Set department staff cost ratio</span>'+
        '<button type="button" id="close" class="close" onclick="$(&quot;.set-department-staff-cost-ratio&quot;).popover(&quot;hide&quot;);">&times;</button>',
        html: true,
        content:  $(element).find("form"),
        sanitize: false,
      })
    })

    $('.set-department-staff-cost-ratio').on('click', (e) => {
      $currentDepartmentStaffCostRatio = $(e.currentTarget)
      $('.set-department-staff-cost-ratio').popover('hide')
      $('.message-error-department-staff-cost-ratio').html('')
      $(e.currentTarget).popover('show')
    });

    $(document).on('click', '.btn-save-department-staff-cost-ratio', (e) => {
      const inputData = $(e.target).closest('form').serialize()
      const departmentStaffCostRatioId = $(e.target).closest('form').data('departmentStaffCostRatioId')
      let httpMethod = 'post'
      let urlDepartmentStaffCostRatio = Routes.department_staff_cost_ratios_path()

      if (!!departmentStaffCostRatioId) {
        httpMethod = 'patch'
        urlDepartmentStaffCostRatio = Routes.department_staff_cost_ratio_path(departmentStaffCostRatioId)
      }

      $.ajax({
        url: urlDepartmentStaffCostRatio,
        type: httpMethod,
        dataType: 'json',
        data: inputData,
        success: (data) => {
          if (data.errors) {
            $('.message-error-department-staff-cost-ratio').html(data.errors)
          } else {
            $currentDepartmentStaffCostRatio.html(data.percent)
            $(e.target).closest('form').data('departmentStaffCostRatioId', data.id)
            $('.set-department-staff-cost-ratio').popover('hide')
            $(`.total-percent-with-division-id-${data.division_id}`).html(data.total_percent_of_division.toFixed(2))
            $('#total-percent').html(data.total_percent.toFixed(2))
          }
        },
        error: (err) => {
          $('.message-error-department-staff-cost-ratio').html('Some thing went wrong !')
          error(err)
        }
      })
    })
  }
}

export default DepartmentStaffCostRatio
