class ReportPL {
  constructor(){
  }

  bindEvents() {
    this.handleMonthOfInput()
    this.handlereportPLChart()
    this.calculateProfitAndGross()
    this.handleButtonExport()
  }

  handleButtonExport() {
    $('.report-pl-wrapper').on('click', '.btn-export', (e) => {
      $('.report_pl').attr('action', Routes.download_project_pl_index_path())
      $('.report_pl').submit();
      $('.report_pl').attr('action', Routes.group_pl_path())
    })
  }
  handleMonthOfInput() {
    $('.month-of-input').datepicker({
      format: 'mm/yyyy',
      viewMode: 'months',
      minViewMode: 'months',
      autoclose: true
    });
  }

  handlereportPLChart() {
    const ctx = document.getElementById('report-pl')
    const reportPLData = $('#report-pl').data('report-pl-data')
    const profit = lodash.map(reportPLData, 'profit');
    let dataIncomeChartOfMonth = []
    let dataExpenseChartOfMonth = []

    lodash.map(reportPLData, 'amount').forEach((data) => {
      dataIncomeChartOfMonth.push(data[0])
      dataExpenseChartOfMonth.push(data[1])
    })

    const myChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: profit,
        datasets: [{
          label: 'Income',
          data: dataIncomeChartOfMonth,
          backgroundColor: ['rgba(255, 99, 132, 0.2)'],
          borderColor: ['rgba(255, 99, 132, 1)'],
          borderWidth: 1
        },
        {
          label: 'Expense',
          data: dataExpenseChartOfMonth,
          backgroundColor: ['rgba(54, 162, 235, 0.2)'],
          borderColor: ['rgba(54, 162, 235, 1)'],
          borderWidth: 1
        }
      ]
      }
    })
  }

  calculateProfitAndGross() {
    const reportGroupTimeRange = $('.report-pl-wrapper .warp-content').data('report-group-time-range')
    let sumGrossForecastProfitOfMonth = 0
    let sumGrossActualProfitOfMonth = 0
    let sumNetForecastProfitOfMonth = 0
    let sumNetActualProfitOfMonth = 0
    let sumForecastIncome = 0
    let sumActualIncome = 0
    sumForecastIncome = parseFloat(this.removeComma($('.sum-forecast-income').html()))
    sumActualIncome = parseFloat(this.removeComma($('.sum-actual-income').html()))

    reportGroupTimeRange.forEach((monthAndYear) => {
      const groupIncomeActualOfMonth = parseFloat(this.removeComma($('.group-income-actual-of-month-'+monthAndYear).html()))
      const groupExpenseActualOfMonth = parseFloat(this.removeComma($('.group-expense-actual-of-month-'+monthAndYear).html()))
      const groupOperationActualOfMonth = parseFloat(this.removeComma($('.group-operation-actual-of-month-'+monthAndYear).html()))

      const groupIncomeForecastOfMonth = parseFloat(this.removeComma($('.group-income-forecast-of-month-'+monthAndYear).html()))
      const groupExpenseForecastOfMonth = parseFloat(this.removeComma($('.group-expense-forecast-of-month-'+monthAndYear).html()))
      const groupOperationForecastOfMonth = parseFloat(this.removeComma($('.group-operation-forecast-of-month-'+monthAndYear).html()))


      sumGrossForecastProfitOfMonth += (groupIncomeForecastOfMonth - groupExpenseForecastOfMonth)
      sumGrossActualProfitOfMonth += (groupIncomeActualOfMonth - groupExpenseActualOfMonth)

      sumNetForecastProfitOfMonth += (groupIncomeForecastOfMonth - groupExpenseForecastOfMonth - groupOperationForecastOfMonth)
      sumNetActualProfitOfMonth += (groupIncomeActualOfMonth - groupExpenseActualOfMonth - groupOperationActualOfMonth)

      $('.gross-profit-forecast-'+monthAndYear).html(this.addDotForNumber((groupIncomeForecastOfMonth - groupExpenseForecastOfMonth).toFixed(2)))
      $('.gross-profit-actual-'+monthAndYear).html(this.addDotForNumber((groupIncomeActualOfMonth - groupExpenseActualOfMonth).toFixed(2)))

      $('.net-profit-forecast-'+monthAndYear).html(this.addDotForNumber((groupIncomeForecastOfMonth - groupExpenseForecastOfMonth - groupOperationForecastOfMonth).toFixed(2)))
      $('.net-profit-actual-'+monthAndYear).html(this.addDotForNumber((groupIncomeActualOfMonth - groupExpenseActualOfMonth - groupOperationActualOfMonth).toFixed(2)))

      if (((groupIncomeForecastOfMonth - groupExpenseForecastOfMonth - groupOperationForecastOfMonth) == 0) || groupIncomeForecastOfMonth == 0 ) {
        $('.net-profit-margin-forecast-'+monthAndYear).html(0)
      } else {
        $('.net-profit-margin-forecast-'+monthAndYear).html(((groupIncomeForecastOfMonth - groupExpenseForecastOfMonth - groupOperationForecastOfMonth)/groupIncomeForecastOfMonth*100).toFixed(2))
      }


      if (((groupIncomeActualOfMonth - groupExpenseActualOfMonth - groupOperationActualOfMonth) == 0) || groupIncomeActualOfMonth == 0 ) {
        $('.net-profit-margin-actual-'+monthAndYear).html(0)
      } else {
        $('.net-profit-margin-actual-'+monthAndYear).html(((groupIncomeActualOfMonth - groupExpenseActualOfMonth - groupOperationActualOfMonth)/groupIncomeActualOfMonth*100).toFixed(2))
      }
    })

    $('.sum-gross-profit-forecast').html(this.addDotForNumber(sumGrossForecastProfitOfMonth.toFixed(2)))
    $('.sum-gross-profit-actual').html(this.addDotForNumber(sumGrossActualProfitOfMonth.toFixed(2)))
    $('.sum-net-profit-forecast').html(this.addDotForNumber(sumNetForecastProfitOfMonth.toFixed(2)))
    $('.sum-net-profit-actual').html(this.addDotForNumber(sumNetActualProfitOfMonth.toFixed(2)))


    if (sumForecastIncome == 0) {
      $('.sum-net-profit-margin-forecast').html(0)
    } else {
      $('.sum-net-profit-margin-forecast').html((sumNetForecastProfitOfMonth/sumForecastIncome*100).toFixed(2))
    }

    if (sumActualIncome == 0) {
      $('.sum-net-profit-margin-actual').html(0)
    } else {
      $('.sum-net-profit-margin-actual').html((sumNetActualProfitOfMonth/sumActualIncome*100).toFixed(2))
    }
  }

  removeComma(string) {
    if(!string) return
    return string.replace(",", "");
  }

  addDotForNumber(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

export default ReportPL
